













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PaginateDealers_paginateDealers_data } from '@/types/intrador';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import ComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {ComboSelect, QueryAutocomplete},
})
export default class InputDealer extends Mixins(InputMixin) {
  @Prop(String) private text!: string;
  @Prop(String) private placeholder?: string;
  @Prop({type: Boolean, default: false}) private onlyUseOptions!: boolean;
  @Prop({type: Array, default: () => []}) private options!: any[];
  @Prop({type: Boolean, default: false}) private fixed!: boolean;

  private get labelText() {
    return this.$it('global.dealer', 'Dealer');
  }

  private valueKey(dealer: PaginateDealers_paginateDealers_data) {
    return dealer.name;
  }

  private itemSelected(item: any) {
    this.$emit('itemSelected', item);
  }

  private selectBare(search: string) {
    (this.$refs.combo as any).enterPressed(search);
  }
}
