















import { Component, Mixins } from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';

@Component({ components: { InspectionAnomaly } })
export default class InspectionInconsistencyAnomaly extends Mixins(InspectionAnomalyMixin) {
  private get inconsistency() {
    const parameters = this.inspection.parameterValues!
      .map((p) => p ? p.parameter : null);

    const parameter = parameters.find((p) =>
      p && this.attributes.parameter_pid && p.id === this.attributes.parameter_pid.toString());

    return {
      parameter: (parameter) ? parameter.name : null,
      metric: (parameter) ? parameter.metric : null,
      old: this.$n(this.attributes.old),
      new: this.$n(this.attributes.new),
    };
  }
}
