var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"client-id":"auctionGraphqlClient","query":require('@/graphql/queries/appraisals/AllAppraisalTypes.gql'),"variables":_vm.variables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [_c('p',[_c('i',{staticClass:"fa fa-asterisk"}),_vm._v(" "+_vm._s(_vm.$it('inspection.action.appraisal.required.type', 'Please specify the type of the appraisal:' )))]),_c('input-select',{staticClass:"mb-3",attrs:{"label":_vm.$it('appraisal.appraisal-type.title', 'Appraisal type'),"tab-index":1,"options":(data) ? data.appraisalTypes : [],"reversed":"","label-field":"name","value-field":"id"},on:{"textChange":_vm.textChange,"optionChange":_vm.optionChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }