

























import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  AuctionType,
  CreateAuctionInput, InspectionList,
  PaginateAssets_paginateAssets_data,
} from '@/types/auction';
import {Inspection_inspection, Inspections_paginateInspections_data} from '@/types/intrador';

@Component({
  components: {AuctionTypeSelect, ActionModal},
})
export default class BulkCreateAuctionByInspection extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('inspection.action.auction.title', 'Create Auctions') as string;
  public icon?: string = 'gavel';
  public permission: string = 'auctions-create';

  protected now = new Date();

  protected auctionType: AuctionType|null = null;

  protected dateRange: DateRange|null = null;

  protected get createAuctionsVariable(): CreateAuctionInput[] | null {
    if (!this.auctionType || !this.dateRange) {
      return null;
    }

    return this.availableItems.map((inspection: InspectionList): CreateAuctionInput => {
      const auction: CreateAuctionInput = {
        auctionType: {
          connect: this.auctionType!.id,
        },

        startingAt: this.dateRange!.start,
        endingAt: this.dateRange!.end,

        asset:  {connect: inspection.asset.id},
      };

      if ('flow' in inspection && inspection.flow) {
        auction.flow = {connect: inspection.flow.id};
      }

      if ('parameterValues' in inspection && inspection.parameterValues) {
        auction.parameterValues = {connect: inspection.parameterValues.map((pv) => pv!.id)};
      }

      return auction;
    });
  }

  protected get availableItems(): any[] {
    // You can extend the inspection when it is not submitted, or when it is declined.
    return this.selected
      .map((item: PaginateAssets_paginateAssets_data | Inspections_paginateInspections_data) => {
        return ('lastInspection' in item) ? item.lastInspection : item;
      })
      .filter((inspection: any) => inspection && inspection.submittedAt !== null);
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }
}
