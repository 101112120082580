var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.create && !_vm.select)?_c('input-text',{attrs:{"label":_vm.$it('global.email', 'Email'),"disabled":_vm.disabled},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):(_vm.select)?_c('input-combo-select',{ref:"comboSelect",attrs:{"label":_vm.label || _vm.$it('global.select-account', 'Account'),"required":_vm.required,"text":_vm.readableValue,"disabled":_vm.disabled,"allow-free-text":_vm.create,"data-key":"paginateUsers","client-id":_vm.clientId,"query":_vm.query,"variables":_vm.variables},scopedSlots:_vm._u([(_vm.create)?{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [_c('div',{staticClass:"item item-create",class:{selected: isSelected, disabled: !(search && search.length > 0)},on:{"click":function($event){return _vm.createUser(search)}}},[_c('label',[_vm._v(_vm._s(_vm.$it('global.email', 'Email'))+" : "),_c('span',{staticClass:"font-mono"},[_vm._v(_vm._s(search))])])])]}}:null,{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"item",class:{selected: selected}},[_c('div',{staticClass:"row no-gutters account"},[_c('div',{staticClass:"col-auto account-avatar"},[_c('img',{attrs:{"src":_vm.avatar(item.email)}})]),_c('div',{staticClass:"col account-info"},[_c('label',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.email))])])])])]}}],null,true),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }