var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('query-auction-detail-modal',{ref:"auctionDetail",on:{"onDelete":function($event){return _vm.$refs.queryDataTable.refresh()}}}),_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateAssets","title":_vm.$it('assets.list.title', 'Assets'),"client-id":"auctionGraphqlClient","query":require('@/graphql/queries/assets/PaginateAssets.gql'),"columns":_vm.columns,"filters":_vm.filters,"default-sort":"ID","default-sort-direction":"desc","searchable-fields":['name'],"can-clear-filters":true},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_c('change-asset-status-action',{attrs:{"register":register,"selected":selected}}),_c('bulk-create-auction-by-inspection',{attrs:{"register":register,"selected":selected}}),_c('bulk-create-appraisals',{attrs:{"register":register,"selected":selected}})]}},{key:"thumb",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.lastInspection && item.lastInspection.firstImage)?_c('img',{staticClass:"thumb",attrs:{"src":item.lastInspection.firstImage.thumbUrl}}):_c('div',{staticClass:"thumb"})]}},{key:"asset",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.lastInspection)?_c('router-link',{attrs:{"to":{ name: 'assets-detail', params: {id: item.id}}}},[_vm._v(" "+_vm._s(value)+" ")]):_c('div',[_vm._v(" "+_vm._s(value)+" ")]),_c('ul',[_c('li',[_c('span',{attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.serialNumber)+" ")])])])]}},{key:"status",fn:function(ref ){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"line"},[(item.status)?_c('asset-status',{attrs:{"asset-id":item.id,"status":item.status,"editable":_vm.$can(['assets-update', 'assets-status-update'])}}):_vm._e()],1)]}},{key:"address",fn:function(ref ){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?[(value.dealer)?_c('router-link',{attrs:{"to":{ name: 'branches-detail', params: { id: value.dealer.id, branchId: value.id }}}},[_vm._v(" "+_vm._s(value.name)+" ")]):_vm._e(),_c('ul',[_c('li',[_vm._v(_vm._s(value.city)+", "+_vm._s(value.country))])])]:_vm._e()]}},{key:"inspection",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('inspection-column',{attrs:{"asset":item,"inspection":value},on:{"onAuctionCreated":function($event){return _vm.$refs.queryDataTable.refresh()}}}):_vm._e()]}},{key:"appraisals",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('appraisal-column',{attrs:{"appraisals":value,"inspection":item.lastInspection},on:{"updated":function($event){return _vm.$refs.queryDataTable.refresh()}}})]}},{key:"auction",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('auction-column',{attrs:{"asset":item,"auction":value},on:{"onOpen":_vm.showAuction,"onCreated":function($event){return _vm.$refs.queryDataTable.refresh()}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }