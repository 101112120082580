




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Appraisal} from '@/types/auction';
import AppraisalModal from '@/components/appraisal/modals/AppraisalsModal.vue';
import BulkCreateAppraisal from '@/components/appraisal/actions/BulkCreateAppraisals.vue';

@Component({
  components: {BulkCreateAppraisal, AppraisalModal},
})
export default class AppraisalColumn extends Vue {
  @Prop(Array) protected appraisals!: Appraisal[];
  @Prop(Object) protected inspection?: any;

  protected get averagePriceText(): string {
    if (this.appraised.length <= 0) {
      return this.$it('appraisal.empty.title', 'No appraisals') as string;
    }

    const prefix = this.$it('global.avg-price', 'Avg price');
    const price = this.$in(this.averagePrice, 'currency');

    return `${prefix}: ${price}`;
  }

  protected get averagePrice(): number {
    const sum = this.appraised.reduce(
      (total: number, appraisal: Appraisal) => (appraisal.value) ? total + appraisal.value : total, 0);

    return sum / this.appraised.length;
  }

  protected get appraised(): Appraisal[] {
    return this.appraisals.filter((appraisal: Appraisal) => appraisal.value !== null);
  }

  protected get notAppraised(): Appraisal[] {
    return this.appraisals.filter((appraisal: Appraisal) => appraisal.value === null);
  }
}
