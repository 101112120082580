


























import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  AuctionType,
  CreateAuctionInput,
  PaginateAssets_paginateAssets_data,
} from '@/types/auction';

@Component({
  components: {AuctionTypeSelect, ActionModal},
})
export default class CreateAuctionAction extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('auction.create.title', 'Create sale') as string;
  public icon: string = 'gavel';
  public permission: string = 'auctions-create';

  @Prop(String) protected flowId?: string;
  @Prop({type: Array, default: () => []}) protected parameterValueIds!: string[];

  protected now = new Date();

  protected auctionType: AuctionType|null = null;

  protected dateRange: DateRange|null = null;

  protected asset: PaginateAssets_paginateAssets_data|null = null;

  public open(asset: PaginateAssets_paginateAssets_data) {
    this.asset = asset;

    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected get minDate() {
    if (this.auctionType && this.auctionType.isExternal) {
      return null;
    }

    return this.now;
  }

  protected get createAuctionVariable(): CreateAuctionInput | null {
    if (!this.auctionType || !this.dateRange || !this.asset) {
      return null;
    }

    const variables: CreateAuctionInput = {
      auctionType: {
        connect: this.auctionType.id,
      },
      startingAt: this.dateRange.start,
      endingAt: this.dateRange.end,
      asset:  {connect: this.asset.id},

      parameterValues: {
        connect: this.parameterValueIds,
      },
    };

    if (this.flowId) {
      variables.flow = {connect: this.flowId};
    }

    return variables;
  }

  protected onSelect() {
    this.open(this.selected[0]);
  }
}
