




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Inspection_inspection_appraisals} from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionDistanceAnomaly from '@/components/inspection/anomalies/InspectionDistanceAnomaly.vue';
import InspectionInconsistencyAnomaly from '@/components/inspection/anomalies/InspectionInconsistencyAnomaly.vue';
import InspectionPictureInPictureAnomaly from '@/components/inspection/anomalies/InspectionPictureInPictureAnomaly.vue';
import InspectionSerialNumberAnomaly from '@/components/inspection/anomalies/InspectionSerialNumberAnomaly.vue';
import InspectionSoldDateAnomaly from '@/components/inspection/anomalies/InspectionSoldDateAnomaly.vue';
import InspectionTimesInDemonstrationAnomaly from '@/components/inspection/anomalies/InspectionTimesInDemonstrationAnomaly.vue';
import InspectionTimesInRentalAnomaly from '@/components/inspection/anomalies/InspectionTimesInRentalAnomaly.vue';
import InspectionWarrantyRegisteredAnomaly from '@/components/inspection/anomalies/InspectionWarrantyRegisteredAnomaly.vue';
import UpdateAppraisal from '@/components/appraisal/actions/UpdateAppraisal.vue';
import DownloadButton from '@/layouts/back-office/elements/download/DownloadButton.vue';
import {Appraisal} from '@/types/auction';

@Component({
  components: {
    DownloadButton,
    Card,
    InspectionDistanceAnomaly,
    InspectionInconsistencyAnomaly,
    InspectionPictureInPictureAnomaly,
    InspectionSerialNumberAnomaly,
    InspectionSoldDateAnomaly,
    InspectionTimesInDemonstrationAnomaly,
    InspectionTimesInRentalAnomaly,
    InspectionWarrantyRegisteredAnomaly,
    UpdateAppraisal,
  },
})
export default class InspectionAppraisals extends Vue {
  @Prop(Array) protected appraisals!: Inspection_inspection_appraisals[] | Appraisal[];

  private current: Inspection_inspection_appraisals | Appraisal | null = null;

  /**
   * Open the update model
   *
   * @param appraisal
   * @private
   */
  private open(appraisal: Inspection_inspection_appraisals) {
    this.current = appraisal;

    this.$nextTick(() => {
      (this.$refs.modal as UpdateAppraisal).open();
    });
  }
}
