


















import { Component, Mixins } from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin.ts';

@Component({ components: { InspectionAnomaly } })
export default class InspectionTimesInDemonstrationAnomaly extends Mixins(InspectionAnomalyMixin) {
  private get timesInDemonstration() {
    return {
      times: this.attributes.times_in_demonstration,
    };
  }
}
