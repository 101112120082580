













import {Component, Prop, Vue} from 'vue-property-decorator';
import {InspectionStatus, PaginateAssets_paginateAssets_data, PaginateAssets_paginateAssets_data_lastInspection} from '@/types/auction';
import EquipmentSituationIcon from '@/components/snapshot/EquipmentSituationIcon.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';

@Component({
  components: {InspectionStatusLabel, EquipmentSituationIcon},
})
export default class InspectionColumn extends Vue {
  @Prop(Object) protected inspection!: PaginateAssets_paginateAssets_data_lastInspection;
  @Prop(Object) protected asset!: PaginateAssets_paginateAssets_data;

  private InspectionStatus = InspectionStatus;
}
