














import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import ActionsRegistrarMixin, {ActionItem} from '@/layouts/back-office/elements/actions/ActionsRegistrarMixin';
import ActionMenu, {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';

@Component({
  components: {ActionMenu},
})
export default class ActionButton extends Mixins(ActionsRegistrarMixin) {
  @Prop(String) protected buttonClasses!: string;
  @Prop({type: Boolean, default: false}) protected multiple!: boolean;
  @Prop(String) protected title?: string;
  @Prop({type: Number, default: ActionMenuDirection.Bottom}) protected direction!: ActionMenuDirection;

  protected hideDisabled: boolean = true;

  get showList(): boolean {
    return this.availableItems.length > ((this.multiple) ? 0 : 1);
  }

  get firstItem(): ActionItem | null {
    if (this.availableItems.length > 0 && (!this.availableItems[0].disabled ||  this.availableItems[0].allowEmpty)) {
      return this.availableItems[0];
    }

    return null;
  }

  private selectFirst() {
    if (this.firstItem && this.firstItem.action) {
      this.firstItem.action();
    }
  }
}
