












import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AppraisalList from '@/components/appraisal/AppraisalList.vue';
import {Appraisal} from '@/types/auction';
import {Inspection} from '@/types/intrador';

@Component({
  components: {AppraisalList, Modal},
})
export default class AppraisalModal extends Vue {
  @Prop(Array) protected appraisals!: Appraisal[];
  @Prop(Object) protected inspection?: any;

  protected get canAppraise(): boolean {
    return this.inspection && this.inspection.submittedAt !== null;
  }

  public open() {
    const modal: Modal = this.$refs.modal as Modal;

    modal.open();
  }
}
