





























































































import {Component, Vue} from 'vue-property-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import CreateAuctionAction from '@/components/auction/actions/CreateAuctionAction.vue';
import AuctionCountdown from '@/components/auction/AuctionCountdown.vue';
import AuctionColumn from '@/components/auction/columns/AuctionColumn.vue';
import GrantAuctionAction from '@/components/auction/actions/GrantAuctionAction.vue';
import InspectionColumn from '@/components/inspection/columns/InspectionColumn.vue';
import {InspectionStatus} from '@/types/intrador';
import {AssetSort, AuctionStatus} from '@/types/auction';
import BulkCreateAppraisals from '@/components/appraisal/actions/BulkCreateAppraisals.vue';
import BulkCreateAuctionByInspection from '@/components/inspection/actions/BulkCreateAuctionByInspection.vue';
import AppraisalColumn from '@/components/appraisal/columns/AppraisalColumn.vue';
import QueryAuctionDetailModal from '@/components/auction/modals/QueryAuctionDetailModal.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import ChangeAssetStatusAction from '@/components/asset/actions/ChangeAssetStatusAction.vue';
import AssetStatus from '@/components/asset/AssetStatus.vue';

type OnOpenEvent = () => void;

@Component({
  components: {
    AssetStatus,
    ChangeAssetStatusAction,
    QueryAuctionDetailModal,
    AppraisalColumn,
    BulkCreateAuctionByInspection,
    BulkCreateAppraisals,
    InspectionColumn,
    GrantAuctionAction, AuctionColumn, AuctionCountdown, CreateAuctionAction, QueryDataTable},
})
export default class Assets extends Vue {
  private InspectionStatus = InspectionStatus;

  protected created() {
    if ('auction-id' in this.$route.query) {
      this.$nextTick(() => {
        this.showAuction(this.$route.query['auction-id'] as string);
      });
    }
  }

  protected showAuction(id: string, openEvent: OnOpenEvent | null = null) {
    const modal: QueryAuctionDetailModal = this.$refs.auctionDetail as QueryAuctionDetailModal;
    modal.show(id, openEvent);
  }

  private get filters(): Filter[] {
    return [
      {
        key: 'statuses',
        title: this.$it('assets.filter.status.title', 'Asset status') as string,
        type: FilterType.QuerySelect,
        options: {
          clientId: 'auctionGraphqlClient',
          query: require('@/graphql/queries/assets/PaginateAssetStatuses.gql'),
          dataKey: 'paginateAssetStatuses',
        },
      },
      {
        key: 'inspectionStatus',
        title: this.$it('assets.filter.inspection.status.title', 'Inspection status') as string,
        type: FilterType.Select,
        options: this.$inspection.status.getFilterOptions([
          InspectionStatus.CREATED,
          InspectionStatus.PLANNED,
          InspectionStatus.STARTED,
          InspectionStatus.FINISHED,
          InspectionStatus.ON_HOLD,
          InspectionStatus.TRANSFER_PENDING,
          InspectionStatus.EXPIRED,
          InspectionStatus.SUBMITTED,
          InspectionStatus.DECLINED,
          InspectionStatus.ACCEPTED,
          InspectionStatus.CANCELLED,
        ]),
        permission: 'inspections-get',
      },
      {
        key: 'needsAppraisal',
        title: this.$it('assets.filter.appraisal.needs.title', 'Needs appraisal') as string,
        type: FilterType.Boolean,
        permission: 'appraisals-get',
      },
      {
        key: 'auctionStatus',
        title: this.$it('assets.filter.auction.status.title', 'Sale Status') as string,
        type: FilterType.Select,
        options: [
          {key: AuctionStatus.PLANNED, label: this.$it('auction.status.planned.title', 'Planned') as string},
          {key: AuctionStatus.RUNNING, label: this.$it('auction.status.running.title', 'Running') as string},
          // tslint:disable-next-line:max-line-length
          {key: AuctionStatus.NEEDS_GRANT, label: this.$it('auction.status.needs-grant.title', 'Needs grant') as string},
          // tslint:disable-next-line:max-line-length
          {key: AuctionStatus.NEEDS_APPROVAL, label: this.$it('auction.status.needs-approval.title', 'Needs approval') as string},
          // tslint:disable-next-line:max-line-length
          {key: AuctionStatus.WAITING_FOR_PAYMENT, label: this.$it('auction.status.waiting-for-payment.title', 'Waiting for payment') as string},
          {key: AuctionStatus.PAID, label: this.$it('auction.status.paid.title', 'Paid') as string},
          {key: AuctionStatus.NO_BIDS, label: this.$it('auction.status.no-bids.title', 'No bids') as string},
          // tslint:disable-next-line:max-line-length
          {key: AuctionStatus.GRANT_DECLINED, label: this.$it('auction.status.grant-declined.title', 'Grant declined') as string},
          {key: AuctionStatus.CANCELLED, label: this.$it('auction.status.cancelled.title', 'Cancelled') as string},
        ],
        permission: 'auctions-get',
      },
    ];
  }

  /**
   * The DataTable columns
   */
  private get columns(): DataTableColumn[] {
    return [
      {
        title: '#',
        key: 'id',
        sortable: true,
        slot: 'thumb',
        class: 'data-table-col-fixed',
        width: 55,
      },
      {
        title: this.$it('assets.col.asset.title', 'Asset') as string,
        key: 'name',
        slot: 'asset',
        sortable: AssetSort.NAME,
        class: 'data-table-col-min data-table-col-middle',
        width: 175,
      },
      {
        title: this.$it('assets.col.address.title', 'Location') as string,
        key: 'lastInspection.address',
        slot: 'address',
        sortable: AssetSort.INSPECTION_ADDRESS_NAME,
        permission: 'inspections-get',
        class: 'data-table-col-min data-table-col-middle',
        width: 125,
      },
      {
        title: this.$it('assets.col.last-inspection.title', 'Last inspection') as string,
        key: 'lastInspection',
        slot: 'inspection',
        sortable: AssetSort.INSPECTION_STATUS,
        permission: 'inspections-get',
        class: 'data-table-col-fixed',
        width: 180,
      },
      {
        title: this.$it('assets.col.appraisal.title', 'Appraisals') as string,
        key: 'appraisals',
        slot: 'appraisals',
        class: 'data-table-col-fixed data-table-col-right',
        permission: 'appraisals-get',
        width: 180,
      },
      {
        title: this.$it('assets.col.auction.title', 'Sale') as string,
        key: 'lastAuction',
        sortable: AssetSort.AUCTION_STATUS,
        slot: 'auction',
        class: 'data-table-col-min',
        permission: 'auctions-get',
        width: 300,
      },
      {
        title: this.$it('assets.col.status.title', 'Status') as string,
        key: 'status',
        sortable: AssetSort.STATUS,
        permission: 'assets-status-get',
        class: 'data-table-col-fixed data-table-col-center',
        width: 175,
      },
    ];
  }
}
