










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class ValueColumn extends Vue {
  @Prop(String) protected label!: string;
}
