











import {Component, Vue, Prop, Model} from 'vue-property-decorator';
import {DocumentNode} from 'graphql';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {Spinner},
})
export default class FileUpload extends Vue {
  @Model('fileChanged') protected model?: FileList|null;

  @Prop(String) protected label!: string;
  @Prop({type: Boolean, default: false}) protected loading!: boolean;
  @Prop(String) protected accept?: string;

  private fileName: string|null = null;

  private fileChanged(event: any) {
    if (event.target && event.target.files.length > 0) {
      const filesAmount = event.target.files.length;

      if (filesAmount === 1) {
        this.fileName = event.target.files[0].name;
      } else {
        this.fileName = filesAmount + ' files';
      }

      this.$emit('fileChanged', event.target.files);
    }
  }

}
