


































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import AuctionMixin from '@/components/auction/AuctionMixin';
import {AuctionDetail} from '@/types/auction';


@Component({
  components: {},
})
export default class AuctionDetailBids extends Mixins(AuctionMixin) {
  @Prop(Object) protected auctionData!: AuctionDetail;
}
