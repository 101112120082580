import {Component, Prop, Vue} from 'vue-property-decorator';
import {AuctionList, GrantStatus} from '@/types/auction';

@Component
export default class AuctionMixin extends Vue {
  @Prop(Object) protected auction!: AuctionList | null;

  protected get needsAction(): boolean {
    return (this.auction && (
      (this.auction.grantedTo && this.auction.grantedTo.youHaveToApprove)
    )) as boolean;
  }

  protected get isPlanned() {
    return this.auction && new Date() < new Date(this.auction.startingAt) ;
  }

  protected get hasStarted() {
    if (!this.auction) {
      return false;
    }

    const now = new Date();
    const startingAt = new Date(this.auction.startingAt);

    return now > startingAt;
  }

  protected get isRunning() {
    if (!this.auction) {
      return false;
    }

    const now = new Date();
    const startingAt = new Date(this.auction.startingAt);
    const endingAt = new Date(this.auction.endingAt);

    return now > startingAt && now < endingAt;
  }

  protected get isEnded() {
    if (!this.auction) {
      return false;
    }

    const now = new Date();
    const endingAt = new Date(this.auction.endingAt);

    return now > endingAt;
  }

  protected get hasBids() {
    return this.auction && this.auction.bids!.paginatorInfo.total > 0;
  }

  protected get isGranted() {
    return this.auction && this.auction.grantedTo && this.auction.grantedTo.status !== GrantStatus.DECLINED;
  }

  protected get grantApproved() {
    return this.auction && this.auction.grantedTo && this.auction.grantedTo.status === GrantStatus.APPROVED;
  }

  protected get isMarkedAsPaid() {
    return this.auction && this.auction.grantedTo && this.auction.grantedTo.paidAt !== null;
  }
}
