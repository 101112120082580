


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {AuctionDetail} from '@/types/auction';
import FilePreview from '@/components/elements/input/FilePreview.vue';
import GraphQlFileUpload from '@/components/elements/input/GraphQlFileUpload.vue';

@Component({
  components: {GraphQlFileUpload, FilePreview},
})
export default class AuctionDetailWinnerInfo extends Vue {
  @Prop(Object) protected auctionData!: AuctionDetail;
}
