
































import {Component, Prop, Vue} from 'vue-property-decorator';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  RequestAuctionGrantApprovalVariables,
} from '@/types/auction';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputUser from '@/components/account/input/InputUser.vue';
import ApproveOrDeclineGrant from '@/components/auction/steps/stateTwo/ApproveOrDeclineGrant.vue';

@Component({
  components: {
    ApproveOrDeclineGrant,
    InputUser, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton,
  },
})
export default class ApproversAssigned extends Vue {
  @Prop(Object) protected auctionData!: any;

  private approversWhere(value: string) {
    return this.auctionData.grantedTo.approvals.filter((approver: any) => {
      return approver.status === value;
    });
  }

}
