
















import {Component, Prop, Vue} from 'vue-property-decorator';
import { saveAs } from 'file-saver';

@Component({})
export default class DownloadButton extends Vue {
  @Prop({type: String, default: 'pdf'}) protected type!: string;

  @Prop(String) protected url!: string;
  @Prop(String) protected fileName!: string;

  protected get classes() {
    if (this.type === 'pdf') {
      return 'download-pdf';
    }
  }

  protected download() {
    saveAs(this.url, this.fileName);
  }
}
