























import {Component, Mixins} from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputSelectMixin from '@/layouts/back-office/elements/input/InputSelectMixin';

@Component({
  components: {InputGroup},
})
export default class InputSelect extends Mixins(InputSelectMixin) {

}
