




















import { Component, Mixins } from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';

@Component({ components: { InspectionAnomaly } })
export default class InspectionSoldDateAnomaly extends Mixins(InspectionAnomalyMixin) {
  private get soldDate() {
    return {
      invoiceDate: this.$id(this.attributes.invoice_date, 'date'),
      daysAgo: this.attributes.days_between_invoice_and_submit,
      submittedDate: this.inspection.submittedAt ? this.$id(this.inspection.submittedAt, 'date') : null,
    };
  }
}
