







































import { Component, Prop, Mixins } from 'vue-property-decorator';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin.ts';

@Component({ components: { } })
export default class InspectionAnomaly extends Mixins(InspectionAnomalyMixin) {
  @Prop(String) protected title!: string;
  @Prop(String) protected help?: string;

  private open = false;

  private mounted() {
    if (this.level === 'ERROR' || this.level === 'WARNING') {
      this.open = true;
    }
  }

  private toggle() {
    this.open = !this.open;
    this.$emit('open', this.open);
  }
}
