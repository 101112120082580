























import { Component, Mixins } from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';

@Component({ components: { InspectionAnomaly } })
export default class InspectionSerialNumberAnomaly extends Mixins(InspectionAnomalyMixin) {
  private get manuallyChecked() {
    return {
      serialNumber: this.inspection.asset!.serialNumber,
    };
  }

  private get autoChecked() {
    return {
      ...this.manuallyChecked,
    };
  }
}
