
























import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '../../../layouts/back-office/elements/Card.vue';

@Component({
  components: {Card},
})
export default class NumberCard extends Vue {
  @Prop({type: [Number, String]}) protected amount!: number | string;
  @Prop(String) protected title!: string;
  @Prop(String) protected info?: string;

  @Prop({type: Boolean, default: false}) protected primary!: boolean;
  @Prop({type: Boolean, default: false}) protected secondary!: boolean;
  @Prop({type: Boolean, default: false}) protected success!: boolean;
  @Prop({type: Boolean, default: false}) protected warning!: boolean;
  @Prop({type: Boolean, default: false}) protected danger!: boolean;
  @Prop({type: Boolean, default: false}) protected planned!: boolean;
  @Prop({type: Boolean, default: false}) protected purple!: boolean;
  @Prop({type: String, default: null}) protected colorCode?: string | null;

  @Prop({type: Boolean, default: false}) protected bold!: boolean;

  get colored() {
    return this.primary || this.secondary || this.success || this.warning || this.danger || this.planned || this.purple
      || this.colorCode !== null;
  }
}
