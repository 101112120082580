var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('combo-select',{ref:"combo",attrs:{"label":_vm.labelText,"searchableFields":['name'],"text":_vm.text,"searchPlaceholder":_vm.labelText,"valueKey":_vm.valueKey,"only-use-options":_vm.onlyUseOptions,"options":_vm.options,"fixed":_vm.fixed,"visibleOnFocus":true,"query":require('@/graphql/queries/PaginateDealers.gql'),"error":_vm.error,"allow-free-text":true,"float-over-input":true,"clearable":_vm.clearable,"required":_vm.required,"tab-index":_vm.tabIndex,"indexKey":"id","dataKey":"paginateDealers"},on:{"focus":function($event){_vm.focus=true},"itemSelected":_vm.itemSelected},scopedSlots:_vm._u([{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [(search && search.length >= 2)?_c('div',{staticClass:"create",on:{"click":function($event){return _vm.selectBare(search)}}},[_c('button',{staticClass:"btn btn-outline-primary"},[_vm._v(" "+_vm._s(_vm.$it('global.create', 'Create'))+" "+_vm._s(_vm.labelText)+": "),_c('span',[_vm._v(_vm._s(search))])])]):_vm._e()]}},{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"use",class:{selected: selected}},[_c('label',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"font-mono",attrs:{"aria-label":_vm.$it('global.dealer-id', 'Dealer Id'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.externalId)+" ")])])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }