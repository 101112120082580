var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"client-id":"auctionGraphqlClient","query":require('@/graphql/queries/auctions/AllAuctionTypes.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [_c('input-select',{attrs:{"label":_vm.$it('global.auction-type', 'Sale type'),"tab-index":1,"options":(data) ? data.auctionTypes : [],"reversed":"","label-field":"name","value-field":"id"},on:{"optionChange":_vm.optionChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }