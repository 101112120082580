

























import {Component, Mixins} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import InputUser from '@/components/account/input/InputUser.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import {Appraisal, UpdateAppraisalInput, UpdateAppraisalVariables} from '@/types/auction';
import AppraisalTypeSelect from '@/components/appraisal/input/AppraisalTypeSelect.vue';
import FileUpload from '@/components/elements/input/FileUpload.vue';

@Component({
  components: {FileUpload, AppraisalTypeSelect, InputText, InputUser, ActionModal},
})
export default class UpdateAppraisal extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('appraisal.update.title', 'Update appraisal') as string;
  public icon?: string = 'dollar-sign';
  public permission = 'appraisals-update';

  protected value: number | null = null;
  protected files: FileList | null = null;

  get variables(): UpdateAppraisalVariables | null {
    if (this.updateAppraisalInput === null) {
      return null;
    }

    const data: UpdateAppraisalVariables = {
      appraisal: this.updateAppraisalInput,
    };

    if (this.files && this.files.length > 0) {
      data.upload = this.files[0];
    }

    return data;
  }

  get updateAppraisalInput(): UpdateAppraisalInput | null {
    if (!this.value) {
      return null;
    }

    return {
      id: this.appraisal.id,
      value: this.value,
    };
  }

  get appraisal(): Appraisal {
    return this.availableItems[0];
  }

  public open() {
    this.onSelect();
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    this.value = this.appraisal.value;

    modal.open();
  }
}
