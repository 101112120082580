





















import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputGroup},
})
export default class InputTextarea extends Mixins(InputMixin) {
}
