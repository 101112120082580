var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auctionData)?_c('div',[_c('div',{staticClass:"row my-2"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"pending-action text-left mt-2 mb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$it('inspection.action.pending', 'Pending next action'))+" ")]),_vm._v(" - "+_vm._s(_vm.$it('auction.action.approve', 'Approve the grant (you are the secondary assigned approver).'))+" ")]),[_vm._v(" "+_vm._s(_vm.$it('inspection.action.approval.other.option.remark', 'Fill in an (optional) remark below'))+" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.remark),expression:"remark"}],staticClass:"form-control",attrs:{"autofocus":""},domProps:{"value":(_vm.remark)},on:{"input":function($event){if($event.target.composing){ return; }_vm.remark=$event.target.value}}})]],2)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('apollo-mutation',{attrs:{"client-id":"auctionGraphqlClient","mutation":require('@/graphql/mutations/auctions/ApproveAuctionGrant.gql'),"variables":_vm.approveVariables},on:{"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mutate = ref.mutate;
var loading = ref.loading;
var error = ref.error;
return [_c('spinner-button',{staticClass:"btn-block btn-outline-primary",attrs:{"spinning":loading},on:{"click":function($event){return mutate()}}},[_vm._v(" "+_vm._s(_vm.$it('auction.approve-grant.title', 'Approve Grant'))+" ")])]}}],null,false,860157543)})],1),_c('div',{staticClass:"col-auto text-center my-auto"},[_vm._v(" "+_vm._s(_vm.$it('global.or', 'or'))+" ")]),_c('div',{staticClass:"col"},[_c('apollo-mutation',{attrs:{"client-id":"auctionGraphqlClient","mutation":require('@/graphql/mutations/auctions/DeclineAuctionGrant.gql'),"variables":_vm.declineVariables},on:{"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mutate = ref.mutate;
var loading = ref.loading;
var error = ref.error;
return [_c('spinner-button',{staticClass:"btn-block btn-outline-danger",attrs:{"spinning":loading},on:{"click":function($event){return mutate()}}},[_vm._v(" "+_vm._s(_vm.$it('auction.decline-grant.title', 'Decline Grant'))+" ")])]}}],null,false,2253367472)})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }