











































import {Component, Prop, Vue} from 'vue-property-decorator';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  RequestAuctionGrantApprovalVariables,
} from '@/types/auction';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputUser from '@/components/account/input/InputUser.vue';

@Component({
  components: {InputUser, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton},
})
export default class AssignSecondApprover extends Vue {
  @Prop(Object) protected auctionData!: any;

  protected user: any = null;


  protected get variables(): RequestAuctionGrantApprovalVariables | null {
    if (!this.user || !this.auctionData) {
      return null;
    }
    return {
      id: this.auctionData.id,
      user: {
        connect: this.user.id,
      },
    };
  }

  protected onDone() {
    this.$emit('mutationResult');
  }

  protected onError(error: any) {
    this.$emit('error', error);
  }


}
