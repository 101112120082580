






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AuctionMixin from '@/components/auction/AuctionMixin';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';
import AuctionDetailModal from '@/components/auction/modals/AuctionDetailModal.vue';

type OnOpenEvent = () => void;

@Component({
  components: {
    AuctionDetailModal,
  },
})
export default class QueryAuctionDetailModal extends Mixins(AuctionMixin) {
  protected id: string | null = null;

  protected isOpen = false;
  protected onOpenEvent: OnOpenEvent | null = null;
  private auctionData: any = null;

  public show(id: string, onOpenEvent: OnOpenEvent | null = null) {
    this.id = id;
    this.open(onOpenEvent);
  }

  public open(onOpenEvent: OnOpenEvent | null = null) {
    this.onOpenEvent = onOpenEvent;
    this.isOpen = true;
  }

  public close() {
    this.id = null;
    this.isOpen = false;
  }

  public openModal() {
    this.$nextTick(() => {
      const modal: Modal = this.$refs.modal as Modal;
      modal.open();
    });
  }

  public refetch() {
    if (this.queryResult) {
      return this.queryResult.refetch();
    }
  }

  protected get variables() {
    return {id: this.id};
  }

  protected dataUpdated(data: any) {
    if (data && data.auction) {
      this.auctionData = data.auction;

      if (this.onOpenEvent !== null) {
        this.onOpenEvent();
      }

      this.openModal();
    }
  }

  private get queryResult(): SmartQuery<any> | null {
    if (this.$refs.apolloQuery) {
      return (this.$refs.apolloQuery as any).getApolloQuery();
    }

    return null;
  }
}
