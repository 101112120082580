import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

export interface InputSelectOptions {
  [key: string]: any;
}

export interface InputSelectItem {
  key: string;
  label: string;
  value: string;

  object?: any;
}

@Component
export default class InputSelectMixin extends Mixins(InputMixin) {
  @Prop({ type: [Object, Array] }) protected options!: InputSelectOptions|any[];
  @Prop({type: String, default: 'label'}) protected keyField!: string;
  @Prop({type: String, default: 'label'}) protected labelField!: string;
  @Prop({type: String, default: 'value'}) protected valueField!: string;
  @Prop({type: Boolean, default: true}) protected sorted!: boolean;
  @Prop(Boolean) protected reversed?: boolean;

  protected selectedKey: string|null = null;

  @Watch('model', {immediate: true})
  protected onModelChange(value: any | null) {
    if (this.selectedKey === null) {
      const initial = this.optionsArray.find((option: InputSelectItem) => option.value === value) ?? null;
      this.selectedKey = (initial) ? initial.key : null;
    }
  }

  protected clear() {
    this.value = null;
    this.selectedKey = null;
    this.$emit('clear');
  }

  protected get optionsArray(): InputSelectItem[] {
    if (Array.isArray(this.options)) {
      return this.options.map((item: any): InputSelectItem => {
        const keyField = item[this.keyField] ?? item[this.labelField];
        return {key: keyField, label: item[this.labelField], value: item[this.valueField], object: item};
      });
    }

    const optionsObject = this.options as InputSelectOptions;
    return Object.keys(optionsObject).reduce((ret: InputSelectItem[], item: any) => {
      const objectValue = optionsObject[item];

      let key = (this.reversed) ? objectValue : item;
      let label = (this.reversed) ? objectValue : item;
      let value = (this.reversed) ? item : objectValue;
      const object = (typeof item === 'object') ? item : (typeof objectValue === 'object') ? objectValue : null;

      if (object !== null) {
        label = (this.labelField in object) ? object[this.labelField] : label;
        value = (this.valueField in object) ? object[this.valueField] : value;
        key = (this.keyField in object) ? object[this.keyField] : (typeof key === 'object') ? label : key;
      }

      ret.push({key, label, value, object});
      return ret;
    }, []);
  }

  protected get optionsArraySorted() {
    return (this.sorted)
      ? this.optionsArray.sort((a, b) => a.label.localeCompare(b.label))
      : this.optionsArray;
  }

  get isFilled(): boolean {
    return this.selectedOption !== null;
  }

  protected get selectedOption(): InputSelectItem|null {
    return this.optionsArray.find((option: InputSelectItem) => option.key === this.selectedKey) ?? null;
  }

  protected get selectedValue(): string|null {
    return (this.selectedOption) ? this.selectedOption.value : null;
  }

  protected selectValue(value: string) {
    this.selectedKey = value;
  }

  @Watch('selectedOption')
  protected selectedChanged(value: InputSelectItem|null) {
    this.value = (value) ? value.value : null;

    this.$emit('keyChange', value?.key);
    this.$emit('textChange', value?.label);
    this.$emit('optionChange', value?.object ?? value);
  }

  public get up(): boolean {
    return this.isFilled;
  }
}
