




























import {Component, Prop, Watch, Mixins} from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';

@Component({
  components: {InputText, InputGroup},
})
export default class InputDate extends Mixins<InputMixin<Date>>(InputMixin) {
  protected date: Date | null = null;

  @Prop(Date) private minDate?: Date;
  @Prop(Date) private maxDate?: Date;

  @Watch('model', {immediate: true})
  private modelUpdated(date: Date | string) {
    if (typeof date === 'string') {
      this.value = new Date(date);
    }
  }

  @Watch('value', {immediate: true})
  private valueUpdated(date: Date) {
    if (!this.sameDate(this.date, date)) {
      this.date = date;
    }
  }

  @Watch('date')
  private dateUpdated(date: Date | null) {
    date = this.toDate12Hour(date);

    if (!this.sameDate(this.date, date, true)) {
      this.date = date;
    }

    if (!this.sameDate(this.value, date)) {
      this.value = date;
    }

    if ('datePicker' in this.$refs) {
      (this.$refs.datePicker as any).hidePopover();
    }
  }

  private toDate12Hour(date: Date | null = null) {
    if (date === null) {
      return null;
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0));
  }

  private sameDate(dateA: Date  | null, dateB: Date | null, hours = false, minutes = false, seconds = false) {
    if (dateA === null || dateB === null) {
      return false;
    }

    const hoursSame = !hours || dateA.getHours() === dateB.getHours();
    const minutesSame = !minutes || dateA.getMinutes() === dateB.getMinutes();
    const secondsSame = !seconds || dateA.getSeconds() === dateB.getSeconds();

    return dateA.getFullYear() === dateB.getFullYear() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate() &&
      hoursSame && minutesSame && secondsSame;
  }

}
