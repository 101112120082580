















import { Component, Mixins } from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin.ts';

@Component({ components: { InspectionAnomaly } })
export default class InspectionPictureInPictureAnomaly extends Mixins(InspectionAnomalyMixin) {
  private get pictureInPicture() {
    const total = this.attributes.amount_of_patches_recaptured + this.attributes.amount_of_patches_original;

    return {
      confidence: `${this.attributes.amount_of_patches_recaptured}/${total}`,
      imagename: '', // this.attributes.image.parameterValue.parameter.name,
    };
  }
}
