




































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import {EntityType, PaginateEntities_paginateEntities_data, PaginateEntitiesVariables} from '@/types/auction';
import i18n from '@/store/i18n';

export interface EntitySelectResponse {
  id: string|null;
  name: string;
}

@Component({
  components: {InputComboSelect},
})
export default class InputEntity extends Mixins(InputMixin) {
  protected value: EntitySelectResponse|null = null;

  @Prop({type: String, default: 'auctionGraphqlClient'}) private clientId!: string;
  @Prop({
    type: Object,
    default: () => require('@/graphql/queries/entities/paginateEntities.gql'),
  }) private query!: object;
  @Prop({type: Array}) private types?: EntityType[];

  @Prop({type: Boolean, default: true}) private create!: boolean;
  @Prop({type: String, default: () => i18n.it('global.entity', 'entity')}) private createText!: string;

  private entity: EntitySelectResponse|string|null = null;

  private created() {
    if (this.model !== null) {
      if (this.model.id === null) {
        this.entity = this.model.name;
      } else {
        this.entity = this.model;
      }
    }
  }

  @Watch('entity')
  private entityChanged(value: PaginateEntities_paginateEntities_data|string|null) {
    if (typeof value === 'string') {
      this.value = {id: null, name: value};
    } else if (value !== null) {
      this.value = {id: value.id, name: value.name};
    } else {
      this.value = null;
    }
  }

  private get readableValue(): string | null {
    return (this.value && 'name' in this.value)
      ? this.value.name
      : null;
  }

  private get variables(): PaginateEntitiesVariables {
    const variables: PaginateEntitiesVariables = {};

    if (this.types) {
      variables.entityTypes = this.types;
    }

    return variables;
  }

  private createEntity(search: string) {
    (this.$refs.comboSelect as any).enterPressed(search);
  }
}
