

































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import AuctionMixin from '@/components/auction/AuctionMixin';
import {AuctionDetail} from '@/types/auction';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';


@Component({
  components: {NumberCard},
})
export default class AuctionDetailStats extends Mixins(AuctionMixin) {

  @Prop(Object) protected auctionData!: AuctionDetail;


  protected get highestBid() {
    if (this.auctionData.highestBid) {
      return this.$in(this.auctionData.highestBid.amount, 'currency');
    }

    return 'No Bids';
  }

  // TODO: remove FUNCTIONAL DUPLICATION!
  private get isExternal() {
    return this.auctionData.auctionType.isExternal;
  }

  private get auctionStatus() {
    return this.auctionData.status.replace('_', ' ');
    // TODO add translations:
    // return this.getAuditStatusTranslation(this.status);
  }
}
