





















import {Component, Mixins} from 'vue-property-decorator';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';

@Component({ components: { InspectionAnomaly } })
export default class InspectionWarrantyRegisteredAnomalyAnomaly extends Mixins(InspectionAnomalyMixin) {
}
