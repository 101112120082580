
























































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  ApproveAuctionGrantVariables, DeclineAuctionGrantVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';

@Component({
  components: {Input, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton},
})
export default class ApproveOrDeclineGrant extends Vue {
  @Prop(Object) protected auctionData!: any;

  private selectedBidIDToGrant: string | null = null;
  private remark: string | null = null;

  protected get approveVariables(): ApproveAuctionGrantVariables | null {
    if (!this.auctionData) {
      return null;
    }

    return {
      id: this.auctionData.id,
      remark: this.remark,
    };
  }

  protected get declineVariables(): DeclineAuctionGrantVariables | null {
    if (!this.auctionData) {
      return null;
    }

    return {
      id: this.auctionData.id,
      remark: this.remark,
    };
  }

  protected onError(error: any) {
    this.$emit('error', error);
  }


}
