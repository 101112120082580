var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-combo-select',{ref:"comboSelect",attrs:{"label":_vm.label || _vm.$it('global.select-entity', 'Entity'),"required":_vm.required,"text":_vm.readableValue,"client-id":_vm.clientId,"query":_vm.query,"data-key":"paginateEntities","variables":_vm.variables,"allow-free-text":_vm.create,"tab-index":_vm.tabIndex},scopedSlots:_vm._u([(_vm.create)?{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [_c('div',{staticClass:"item item-create",class:{selected: isSelected, disabled: !(search && search.length > 0)},on:{"click":function($event){return _vm.createEntity(search)}}},[_c('label',[_vm._v(_vm._s(_vm.$it('global.create', 'Create'))+" "+_vm._s(_vm.createText)+": "),_c('span',{staticClass:"font-mono"},[_vm._v(_vm._s(search))])])])]}}:null,{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"item",class:{selected: selected}},[_c('label',[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(_vm.entity),callback:function ($$v) {_vm.entity=$$v},expression:"entity"}})}
var staticRenderFns = []

export { render, staticRenderFns }