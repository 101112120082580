














































import { Component, Mixins } from 'vue-property-decorator';
import { Inspection_inspection_logs } from '@/types/intrador';
import InspectionAnomaly from '@/components/inspection/anomalies/InspectionAnomaly.vue';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';

interface LatLng {
  lat: number;
  lng: number;
}

interface LatLngBounds {
  extend(coordinate: LatLng): void;
}

@Component({ components: { InspectionAnomaly } })
export default class InspectionDistanceAnomaly extends Mixins(InspectionAnomalyMixin) {
  private mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    gestureHandling: 'cooperative',
  };

  private mapAccuracyCircle = {
    strokeColor: '#2197AE',
    strokeOpacity: 0.25,
    strokeWeight: 1,
    fillColor: '#2197AE',
    fillOpacity: 0.1,
  };

  private mapIcon = {
    url: '/static/map/marker.svg',
    size: { width: 32, height: 32 },
    anchor: { x: 16, y: 16 },
  };

  private get center() {
    if (this.inspection.address && this.inspection.address.latitude && this.inspection.address.longitude) {
      return {
        lat: this.inspection.address.latitude,
        lng: this.inspection.address.longitude,
      };
    }

    return {
      lat: 51.670987814052054,
      lng: 5.607280313394596,
    };
  }

  private get bounds() {
    const bounds: LatLngBounds = new (window as any).google.maps.LatLngBounds();

    if (this.center) {
      bounds.extend(this.center);
    }

    return this.inspection.logs?.reduce((bnds: LatLngBounds, log: Inspection_inspection_logs | null) => {
      if (log && log.latitude && log.longitude) {
        bnds.extend({
          lat: log.latitude,
          lng: log.longitude,
        });
      }

      return bnds;
    }, bounds);
  }

  private get replace() {
    return {
      distance: this.$in(this.attributes.distance, 'distance'),
    };
  }

  private get logsWithAccuracy() {
    return (this.inspection.logs || []).map((l) => l!.accuracy);
  }

  get averageAccuracy() {
    return this.$in(Math.round(this.logsWithAccuracy
      .reduce( ( p , c ) => p + c, 0 ) / this.logsWithAccuracy.length), 'distance');
  }

  get minimumAccuracy() {
    return this.$in(Math.round(Math.min.apply(null, this.logsWithAccuracy)), 'distance');
  }

  get maximumAccuracy() {
    return this.$in(Math.round(Math.max.apply(null, this.logsWithAccuracy)), 'distance');
  }

  private fit() {
    this.$nextTick(() => {
      if (this.$refs.map) {
        (this.$refs.map as any).$mapPromise.then((map: any) => {
          map.fitBounds(this.bounds);
        });
      }
    });
  }

  private mounted() {
    this.fit();
  }
}
