













































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuctionDetail} from '@/types/auction';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import AuctionDetailBids from '@/components/auction/elements/AuctionDetailBids.vue';
import AddExternalGrant from '@/components/auction/steps/stateOne/AddExternalGrant.vue';
import AuctionGranted from '@/components/auction/steps/stateOne/AuctionGranted.vue';
import AuctionInProgress from '@/components/auction/steps/stateOne/AuctionInProgress.vue';
import GrantAuction from '@/components/auction/steps/stateOne/GrantAuction.vue';
import ApproversAssigned from '@/components/auction/steps/stateTwo/ApproversAssigned.vue';
import AssignSecondApprover from '@/components/auction/steps/stateTwo/AssignSecondApprover.vue';
import AuctionMixin from '@/components/auction/AuctionMixin';
import MarkAsPaid from '@/components/auction/steps/stateThree/MarkAsPaid.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import DeleteAuctionAction from '@/components/auction/actions/DeleteAuctionAction.vue';
import ExtendAuctionAction from '@/components/auction/actions/ExtendAuctionAction.vue';


@Component({
  components: {
    ExtendAuctionAction,
    DeleteAuctionAction,
    ActionButton,
    Modal,
    MarkAsPaid,
    AssignSecondApprover,
    ApproversAssigned,
    GrantAuction,
    AuctionInProgress,
    AuctionGranted,
    AddExternalGrant,
    AuctionDetailBids,
    AuctionDetailStats,
  },
})
export default class AuctionDetailModal extends Mixins(AuctionMixin) {
  @Prop(Object) protected auction!: AuctionDetail | null;

  public open() {
    const modal: Modal = this.$refs.modal as Modal;
    modal.open();
  }

  public close() {
    const modal: Modal = this.$refs.modal as Modal;
    modal.close();

    this.closed();
  }

  public refetch() {
    this.$emit('onRefetch');
  }

  public closed() {
    this.$emit('onClose');
  }

  protected deleted() {
    this.close();
    this.$emit('onDelete');
  }

  private get isExternal() {
    return  this.auction && this.auction.auctionType.isExternal;
  }

  private get isStepZero() {
    return !this.isEnded && this.isRunning || this.isPlanned && !this.hasBids;
  }

  private get isStepOneExternal() {
    return !this.isPlanned && this.isEnded && !this.isGranted;
  }

  private get isStepOne() {
    return !this.isPlanned && this.hasBids && this.isEnded && !this.isGranted;
  }

  private get isStepTwo() {
    return !this.isPlanned && this.hasBids && this.isEnded && this.isGranted && this.hasBids;
  }

  private get isStepThree() {
    return this.isStepTwo && this.grantApproved;
  }
}
