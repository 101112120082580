































import {Component, Mixins, Vue} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  AuctionList,
  GrantAuctionVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';

@Component({
  components: {Input, AuctionTypeSelect, ActionModal},
})
export default class GrantAuctionAction extends Mixins(ActionMixin) {
  protected auction: AuctionList | null = null;
  protected bidId: string | null = null;

  public open(auction: AuctionList) {
    this.auction = auction;

    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected get grantAuctionVariable(): GrantAuctionVariables | null {
    if (!this.auction || !this.bidId) {
      return null;
    }

    return {
      id: this.auction.id,
      bidId: this.bidId,
    };
  }
}
