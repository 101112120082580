
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  MarkAuctionAsPaidVariables,
} from '@/types/auction';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputUser from '@/components/account/input/InputUser.vue';
import FileUpload from '@/components/elements/input/FileUpload.vue';
import FilePreview from '@/components/elements/input/FilePreview.vue';
import GraphQlFileUpload from '@/components/elements/input/GraphQlFileUpload.vue';


@Component({
  components: {
    GraphQlFileUpload,
    FilePreview, InputUser, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton},
})
export default class MarkedAsPaid extends Vue {
  @Prop(Object) protected auctionData!: any;

  protected get variables(): MarkAuctionAsPaidVariables | null {
    if (!this.auctionData) {
      return null;
    }

    return {
      id: this.auctionData.id,
    };
  }

  protected onDone() {
    this.$emit('mutationResult');
  }

  protected onError(error: any) {
    this.$emit('error', error);
  }
}
