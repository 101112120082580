







































































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  ContactBelongsTo,
  CreateContactInput, CreateEntityInput, EntityBelongsTo,
  GrantAuctionVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import FileUpload from '@/components/elements/input/FileUpload.vue';

@Component({
  components: {
    FileUpload,
    InputTextarea,
    InputText,
    Input,
    AuctionTypeSelect,
    Modal,
    AuctionDetailStats,
    SpinnerButton,
  },
})
export default class AddExternalGrant extends Vue {
  @Prop(Object) protected auctionData!: any;

  protected companyName: string | null = null;
  protected firstName: string | null = null;
  protected lastName: string | null = null;
  protected phone: string | null = null;
  protected mobile: string | null = null;
  protected email: string | null = null;
  protected amount: string | null = null;
  protected remark: string | null = null;

  protected upload: FileList | null = null;

  protected get context(): any | null {
    return {
      hasUpload: this.upload !== null,
    };
  }

  protected get variables(): GrantAuctionVariables | null {
    if (!this.auctionData || !this.amount || !this.contact) {
      return null;
    }

    const amount = parseInt(this.amount, 10);
    if (isNaN(amount)) {
      return null;
    }

    return {
      amount,
      id: this.auctionData.id,
      contact: this.contact,
      entity: this.entity,
      remark: this.remark,
      upload: (this.upload !== null) ? this.upload[0] : null,
    };
  }

  protected get entity(): EntityBelongsTo {
    return {
      create: {
        name: this.companyName!,
      },
    };
  }

  protected get contact(): ContactBelongsTo {
    return {
      create: {
        email: this.email!,
        firstName: this.firstName!,
        lastName: this.lastName!,
        phone: this.phone,
        mobile: this.mobile,
      },
    };
  }

  protected get canPersist() {
    if (this.companyName === null || this.companyName.length < 2) {
      return false;
    }

    if (this.firstName === null || this.firstName.length < 2) {
      return false;
    }

    if (this.lastName === null || this.lastName.length < 2) {
      return false;
    }

    if (this.email === null || !this.$validateEmail(this.email)) {
      return false;
    }

    return !(this.amount === null || isNaN(parseInt(this.amount, 10)));
  }

  protected onDone() {
    this.$emit('mutationResult');
  }

  protected onError(error: any) {
    this.$emit('error', error);
  }

}
