















































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import md5 from 'md5';
import {
  EntityType,
  PaginateUsers_paginateUsers_data,
  PaginateUsersVariables,
} from '@/types/auction';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';

export interface UserSelectResponse {
  id: string|null;
  name: string|null;
  email: string;
}

@Component({
  components: {InputText, InputComboSelect},
})
export default class InputUser extends Mixins(InputMixin) {
  protected value: UserSelectResponse|null = null;

  @Prop({type: String, default: 'auctionGraphqlClient'}) protected clientId!: string;
  @Prop({type: Object, default: () => require('@/graphql/queries/users/SearchUsers.gql')}) protected query!: object;
  @Prop({type: Array}) protected types?: EntityType[];
  @Prop({type: Array}) protected entityIds?: string[];

  @Prop({type: Boolean, default: true}) protected create!: boolean;
  @Prop({type: Boolean, default: true}) protected select!: boolean;

  protected user: UserSelectResponse|string|null = null;

  @Watch('user')
  protected userChanged(value: PaginateUsers_paginateUsers_data|string|null) {
    if (typeof value === 'string') {
      this.value = {id: null, name: null, email: value};
    } else if (value !== null) {
      this.value = {id: value.id, name: null, email: value.email};
    } else {
      this.value = null;
    }
  }

  protected get readableValue(): string | null {
    if (this.value === null) {
      return null;
    }

    if (this.value.name) {
      return this.value.name;
    }

    return this.value.email;
  }

  protected avatar(email: string) {
    return `https://www.gravatar.com/avatar/${md5(email)}?s=64`;
  }

  protected get variables(): PaginateUsersVariables {
    const variables: PaginateUsersVariables = {};

    if (this.types) {
      variables.entityTypes = this.types;
    }
    if (this.entityIds) {
      variables.entityIds = this.entityIds;
    }

    return variables;
  }

  private createUser(search: string) {
    (this.$refs.comboSelect as any).enterPressed(search);
  }
}
