

















import {Component, Mixins} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  AuctionList, UpdateAuctionVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';

@Component({
  components: {InputDate, InputTextarea, Input, AuctionTypeSelect, ActionModal},
})
export default class ExtendAuctionAction extends Mixins<ActionMixin<any>>(ActionMixin) {
  protected auction: AuctionList | null = null;
  protected endingAt: Date | null = null;


  public open(auction: AuctionList) {
    this.auction = auction;
    this.endingAt = new Date(this.auction.endingAt);

    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected onSelect() {
    if (this.firstAvailable) {
      this.open(this.firstAvailable);
    }
  }

  protected get updateVariable(): UpdateAuctionVariables | null {
    if (!this.auction || !this.endingAt) {
      return null;
    }

    return {
      auction: {
        id: this.auction.id,
        endingAt: this.endingAt,
      },
    };
  }
}
