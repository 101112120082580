










import {Component, Vue, Prop} from 'vue-property-decorator';
import {DocumentNode} from 'graphql';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {Spinner},
})
export default class FilePreview extends Vue {
  @Prop(Object) protected file!: any;


}
