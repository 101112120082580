

















import {Component, Mixins, Watch} from 'vue-property-decorator';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {AuctionList, RequestAuctionGrantApprovalVariables} from '@/types/auction';
import InputUser from '@/components/account/input/InputUser.vue';

@Component({
  components: {InputUser, ActionModal, InputTextarea},
})
export default class RequestAuctionGrantApprovalAction  extends Mixins(ActionMixin) {
  protected auction: AuctionList | null = null;
  protected user: any = null;

  public open(auction: AuctionList) {
    this.auction = auction;

    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected get requestAuctionGrantApprovalVariables(): RequestAuctionGrantApprovalVariables | null {
    if (!this.user || !this.auction) {
      return null;
    }

    return {
      id: this.auction.id,
      user: {
        connect: this.user.id,
      },
    };
  }
}
