











import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class AuctionValueColumn extends Vue {
  @Prop(String) protected label!: string;
  @Prop(String) protected fontColor!: string;
  @Prop(String) protected paddingTop!: string;
  @Prop(String) protected backgroundColor!: string;
  @Prop(String) protected labelBackgroundColor!: string;
  @Prop(String) protected fontWeight!: string;
  @Prop(String) protected fontStyle!: string;
  @Prop(String) protected fontSize!: string;
}
