



































import {Component, Mixins, Vue} from 'vue-property-decorator';
import AuctionMixin from '@/components/auction/AuctionMixin';
import GrantAuctionAction from '@/components/auction/actions/GrantAuctionAction.vue';
import RequestAuctionGrantApprovalAction from '@/components/auction/actions/RequestAuctionGrantApprovalAction.vue';
import ApproveAuctionGrantAction from '@/components/auction/actions/ApproveAuctionGrantAction.vue';
import DeclineAuctionGrantAction from '@/components/auction/actions/DeclineAuctionGrantAction.vue';
import MarkAuctionAsPaidAction from '@/components/auction/actions/MarkAuctionAsPaidAction.vue';

@Component({
  components: {
    MarkAuctionAsPaidAction,
    DeclineAuctionGrantAction,
    ApproveAuctionGrantAction, RequestAuctionGrantApprovalAction, GrantAuctionAction,
  },
})
export default class AuctionActionColumn extends Mixins(AuctionMixin) {
}
