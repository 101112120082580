














import {Component, Mixins} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  AuctionList, ApproveAuctionGrantVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({
  components: {ConfirmActionModal, InputTextarea, Input, AuctionTypeSelect},
})
export default class GrantAuctionAction extends Mixins<ActionMixin<any>>(ActionMixin) {
  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected get auction(): AuctionList  {
    return this.selected[0];
  }
}
