




import {Component, Vue, Prop} from 'vue-property-decorator';
import {DocumentNode} from 'graphql';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import FileUpload from '@/components/elements/input/FileUpload.vue';

@Component({
  components: {FileUpload, Spinner},
})
export default class GraphQlFileUpload extends Vue {
  @Prop(String) protected clientId!: string;
  @Prop(Object) protected mutation!: DocumentNode;
  @Prop(Object) protected variables!: string;

  @Prop({type: String, default: 'upload'}) protected uploadKey!: string;

  @Prop(String) protected label!: string;

  @Prop(String) protected accept?: string;

  protected loading: boolean = false;

  private fileChanged(fileList: FileList) {
    if (fileList.length > 0) {
      this.loading = true;

      const files = Array.from(fileList);

      files.forEach((file: any) => {
        const uploadVariables: any = {};
        uploadVariables[this.uploadKey] = file;

        this.$apollo.mutate({
          client: this.clientId,
          mutation: this.mutation,
          variables: Object.assign(uploadVariables, this.variables),
          context: {
            hasUpload: true,
          },
        }).then(() => {
          this.loading = false;
        });
      });
    }
  }

}
