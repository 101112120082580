










import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  GrantAuctionVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import AuctionCountdown from '@/components/auction/AuctionCountdown.vue';

@Component({
  components: {Input, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton, AuctionCountdown},
})
export default class AuctionInProgress extends Vue {
  @Prop(Object) protected auctionData!: any;

}
