/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCreateAppraisals
// ====================================================

export interface BulkCreateAppraisals_bulkCreateAppraisals_data_appraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals_data_inspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals_data_appraiser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals_data_valuationAgency {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals_data_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals_data {
  __typename: "Appraisal";
  /**
   * The identifier of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: number | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: BulkCreateAppraisals_bulkCreateAppraisals_data_appraisalType;
  /**
   * The inspection that belongs to the appraisal
   */
  inspection: BulkCreateAppraisals_bulkCreateAppraisals_data_inspection;
  /**
   * The appraiser that belongs to the appraisal
   */
  appraiser: BulkCreateAppraisals_bulkCreateAppraisals_data_appraiser | null;
  /**
   * The valuation agency that belongs to the appraisal
   */
  valuationAgency: BulkCreateAppraisals_bulkCreateAppraisals_data_valuationAgency | null;
  /**
   * The appraisal report
   */
  report: BulkCreateAppraisals_bulkCreateAppraisals_data_report | null;
}

export interface BulkCreateAppraisals_bulkCreateAppraisals {
  __typename: "bulkCreateAppraisalsBulk";
  /**
   * A list of created Appraisal
   */
  data: BulkCreateAppraisals_bulkCreateAppraisals_data[];
}

export interface BulkCreateAppraisals {
  /**
   * Bulk create an appraisals
   */
  bulkCreateAppraisals: BulkCreateAppraisals_bulkCreateAppraisals;
}

export interface BulkCreateAppraisalsVariables {
  appraisals: CreateAppraisalInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAppraisal
// ====================================================

export interface CreateAppraisal_createAppraisal_appraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
}

export interface CreateAppraisal_createAppraisal_inspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
}

export interface CreateAppraisal_createAppraisal_appraiser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface CreateAppraisal_createAppraisal_valuationAgency {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface CreateAppraisal_createAppraisal_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface CreateAppraisal_createAppraisal {
  __typename: "Appraisal";
  /**
   * The identifier of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: number | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: CreateAppraisal_createAppraisal_appraisalType;
  /**
   * The inspection that belongs to the appraisal
   */
  inspection: CreateAppraisal_createAppraisal_inspection;
  /**
   * The appraiser that belongs to the appraisal
   */
  appraiser: CreateAppraisal_createAppraisal_appraiser | null;
  /**
   * The valuation agency that belongs to the appraisal
   */
  valuationAgency: CreateAppraisal_createAppraisal_valuationAgency | null;
  /**
   * The appraisal report
   */
  report: CreateAppraisal_createAppraisal_report | null;
}

export interface CreateAppraisal {
  /**
   * Create a appraisal
   */
  createAppraisal: CreateAppraisal_createAppraisal;
}

export interface CreateAppraisalVariables {
  appraisal: CreateAppraisalInput;
  upload?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppraisal
// ====================================================

export interface UpdateAppraisal_updateAppraisal_appraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
}

export interface UpdateAppraisal_updateAppraisal_inspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
}

export interface UpdateAppraisal_updateAppraisal_appraiser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface UpdateAppraisal_updateAppraisal_valuationAgency {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UpdateAppraisal_updateAppraisal_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface UpdateAppraisal_updateAppraisal {
  __typename: "Appraisal";
  /**
   * The identifier of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: number | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: UpdateAppraisal_updateAppraisal_appraisalType;
  /**
   * The inspection that belongs to the appraisal
   */
  inspection: UpdateAppraisal_updateAppraisal_inspection;
  /**
   * The appraiser that belongs to the appraisal
   */
  appraiser: UpdateAppraisal_updateAppraisal_appraiser | null;
  /**
   * The valuation agency that belongs to the appraisal
   */
  valuationAgency: UpdateAppraisal_updateAppraisal_valuationAgency | null;
  /**
   * The appraisal report
   */
  report: UpdateAppraisal_updateAppraisal_report | null;
}

export interface UpdateAppraisal {
  /**
   * Update a appraisal
   */
  updateAppraisal: UpdateAppraisal_updateAppraisal;
}

export interface UpdateAppraisalVariables {
  appraisal: UpdateAppraisalInput;
  upload?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkUpdateAssets
// ====================================================

export interface BulkUpdateAssets_bulkUpdateAssets_data_status {
  __typename: "AssetStatus";
  id: string;
  name: string;
}

export interface BulkUpdateAssets_bulkUpdateAssets_data {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
  /**
   * The serial number of the asset, this is used to identify single assets within the entire system
   */
  serialNumber: string | null;
  /**
   * The status of the asset
   */
  status: BulkUpdateAssets_bulkUpdateAssets_data_status | null;
}

export interface BulkUpdateAssets_bulkUpdateAssets {
  __typename: "bulkUpdateAssetsBulk";
  /**
   * A list of created Asset
   */
  data: BulkUpdateAssets_bulkUpdateAssets_data[];
}

export interface BulkUpdateAssets {
  /**
   * Bulk update an Asset
   */
  bulkUpdateAssets: BulkUpdateAssets_bulkUpdateAssets;
}

export interface BulkUpdateAssetsVariables {
  assets: UpdateAssetInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveAuctionGrant
// ====================================================

export interface ApproveAuctionGrant_approveAuctionGrant_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface ApproveAuctionGrant_approveAuctionGrant_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface ApproveAuctionGrant_approveAuctionGrant_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: ApproveAuctionGrant_approveAuctionGrant_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: ApproveAuctionGrant_approveAuctionGrant_bids_data_bidderEntity | null;
}

export interface ApproveAuctionGrant_approveAuctionGrant_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: ApproveAuctionGrant_approveAuctionGrant_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: ApproveAuctionGrant_approveAuctionGrant_bids_data[];
}

export interface ApproveAuctionGrant_approveAuctionGrant_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: ApproveAuctionGrant_approveAuctionGrant_grantedTo_approvals_approver;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: ApproveAuctionGrant_approveAuctionGrant_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: ApproveAuctionGrant_approveAuctionGrant_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: ApproveAuctionGrant_approveAuctionGrant_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: ApproveAuctionGrant_approveAuctionGrant_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: ApproveAuctionGrant_approveAuctionGrant_grantedTo_bidderEntity | null;
}

export interface ApproveAuctionGrant_approveAuctionGrant_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface ApproveAuctionGrant_approveAuctionGrant {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: ApproveAuctionGrant_approveAuctionGrant_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: ApproveAuctionGrant_approveAuctionGrant_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: ApproveAuctionGrant_approveAuctionGrant_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: ApproveAuctionGrant_approveAuctionGrant_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: ApproveAuctionGrant_approveAuctionGrant_asset;
}

export interface ApproveAuctionGrant {
  /**
   * Approve an auction grant
   */
  approveAuctionGrant: ApproveAuctionGrant_approveAuctionGrant;
}

export interface ApproveAuctionGrantVariables {
  id: string;
  remark?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCreateAuction
// ====================================================

export interface BulkCreateAuction_bulkCreateAuction_data_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface BulkCreateAuction_bulkCreateAuction_data_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface BulkCreateAuction_bulkCreateAuction_data_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: BulkCreateAuction_bulkCreateAuction_data_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: BulkCreateAuction_bulkCreateAuction_data_bids_data_bidderEntity | null;
}

export interface BulkCreateAuction_bulkCreateAuction_data_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: BulkCreateAuction_bulkCreateAuction_data_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: BulkCreateAuction_bulkCreateAuction_data_bids_data[];
}

export interface BulkCreateAuction_bulkCreateAuction_data_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: BulkCreateAuction_bulkCreateAuction_data_grantedTo_approvals_approver;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: BulkCreateAuction_bulkCreateAuction_data_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: BulkCreateAuction_bulkCreateAuction_data_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: BulkCreateAuction_bulkCreateAuction_data_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: BulkCreateAuction_bulkCreateAuction_data_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: BulkCreateAuction_bulkCreateAuction_data_grantedTo_bidderEntity | null;
}

export interface BulkCreateAuction_bulkCreateAuction_data_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface BulkCreateAuction_bulkCreateAuction_data {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: BulkCreateAuction_bulkCreateAuction_data_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: BulkCreateAuction_bulkCreateAuction_data_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: BulkCreateAuction_bulkCreateAuction_data_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: BulkCreateAuction_bulkCreateAuction_data_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: BulkCreateAuction_bulkCreateAuction_data_asset;
}

export interface BulkCreateAuction_bulkCreateAuction {
  __typename: "bulkCreateAuctionBulk";
  /**
   * The error messages given
   */
  errorMessages: string[];
  /**
   * A list of created Auction
   */
  data: BulkCreateAuction_bulkCreateAuction_data[];
}

export interface BulkCreateAuction {
  /**
   * Bulk create multiple auctions
   */
  bulkCreateAuction: BulkCreateAuction_bulkCreateAuction;
}

export interface BulkCreateAuctionVariables {
  auctions: CreateAuctionInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAuction
// ====================================================

export interface CreateAuction_createAuction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface CreateAuction_createAuction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface CreateAuction_createAuction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface CreateAuction_createAuction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface CreateAuction_createAuction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: CreateAuction_createAuction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: CreateAuction_createAuction_bids_data_bidderEntity | null;
}

export interface CreateAuction_createAuction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: CreateAuction_createAuction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: CreateAuction_createAuction_bids_data[];
}

export interface CreateAuction_createAuction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface CreateAuction_createAuction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface CreateAuction_createAuction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: CreateAuction_createAuction_grantedTo_approvals_approver;
}

export interface CreateAuction_createAuction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface CreateAuction_createAuction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface CreateAuction_createAuction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface CreateAuction_createAuction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface CreateAuction_createAuction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: CreateAuction_createAuction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: CreateAuction_createAuction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: CreateAuction_createAuction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: CreateAuction_createAuction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: CreateAuction_createAuction_grantedTo_bidderEntity | null;
}

export interface CreateAuction_createAuction_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface CreateAuction_createAuction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: CreateAuction_createAuction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: CreateAuction_createAuction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: CreateAuction_createAuction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: CreateAuction_createAuction_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: CreateAuction_createAuction_asset;
}

export interface CreateAuction {
  /**
   * Create an auction
   */
  createAuction: CreateAuction_createAuction;
}

export interface CreateAuctionVariables {
  auction: CreateAuctionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineAuctionGrant
// ====================================================

export interface DeclineAuctionGrant_declineAuctionGrant_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface DeclineAuctionGrant_declineAuctionGrant_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface DeclineAuctionGrant_declineAuctionGrant_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: DeclineAuctionGrant_declineAuctionGrant_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: DeclineAuctionGrant_declineAuctionGrant_bids_data_bidderEntity | null;
}

export interface DeclineAuctionGrant_declineAuctionGrant_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: DeclineAuctionGrant_declineAuctionGrant_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: DeclineAuctionGrant_declineAuctionGrant_bids_data[];
}

export interface DeclineAuctionGrant_declineAuctionGrant_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: DeclineAuctionGrant_declineAuctionGrant_grantedTo_approvals_approver;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: DeclineAuctionGrant_declineAuctionGrant_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: DeclineAuctionGrant_declineAuctionGrant_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: DeclineAuctionGrant_declineAuctionGrant_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: DeclineAuctionGrant_declineAuctionGrant_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: DeclineAuctionGrant_declineAuctionGrant_grantedTo_bidderEntity | null;
}

export interface DeclineAuctionGrant_declineAuctionGrant_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface DeclineAuctionGrant_declineAuctionGrant {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: DeclineAuctionGrant_declineAuctionGrant_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: DeclineAuctionGrant_declineAuctionGrant_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: DeclineAuctionGrant_declineAuctionGrant_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: DeclineAuctionGrant_declineAuctionGrant_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: DeclineAuctionGrant_declineAuctionGrant_asset;
}

export interface DeclineAuctionGrant {
  /**
   * Decline an auction grant
   */
  declineAuctionGrant: DeclineAuctionGrant_declineAuctionGrant;
}

export interface DeclineAuctionGrantVariables {
  id: string;
  remark?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAuction
// ====================================================

export interface DeleteAuction_deleteAuction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface DeleteAuction_deleteAuction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface DeleteAuction_deleteAuction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface DeleteAuction_deleteAuction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface DeleteAuction_deleteAuction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: DeleteAuction_deleteAuction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: DeleteAuction_deleteAuction_bids_data_bidderEntity | null;
}

export interface DeleteAuction_deleteAuction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: DeleteAuction_deleteAuction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: DeleteAuction_deleteAuction_bids_data[];
}

export interface DeleteAuction_deleteAuction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface DeleteAuction_deleteAuction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface DeleteAuction_deleteAuction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: DeleteAuction_deleteAuction_grantedTo_approvals_approver;
}

export interface DeleteAuction_deleteAuction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface DeleteAuction_deleteAuction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface DeleteAuction_deleteAuction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface DeleteAuction_deleteAuction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface DeleteAuction_deleteAuction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: DeleteAuction_deleteAuction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: DeleteAuction_deleteAuction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: DeleteAuction_deleteAuction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: DeleteAuction_deleteAuction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: DeleteAuction_deleteAuction_grantedTo_bidderEntity | null;
}

export interface DeleteAuction_deleteAuction_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface DeleteAuction_deleteAuction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: DeleteAuction_deleteAuction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: DeleteAuction_deleteAuction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: DeleteAuction_deleteAuction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: DeleteAuction_deleteAuction_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: DeleteAuction_deleteAuction_asset;
}

export interface DeleteAuction {
  /**
   * delete an auction
   */
  deleteAuction: DeleteAuction_deleteAuction | null;
}

export interface DeleteAuctionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GrantAuction
// ====================================================

export interface GrantAuction_grantAuction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface GrantAuction_grantAuction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface GrantAuction_grantAuction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface GrantAuction_grantAuction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface GrantAuction_grantAuction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: GrantAuction_grantAuction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: GrantAuction_grantAuction_bids_data_bidderEntity | null;
}

export interface GrantAuction_grantAuction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: GrantAuction_grantAuction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: GrantAuction_grantAuction_bids_data[];
}

export interface GrantAuction_grantAuction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface GrantAuction_grantAuction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface GrantAuction_grantAuction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: GrantAuction_grantAuction_grantedTo_approvals_approver;
}

export interface GrantAuction_grantAuction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface GrantAuction_grantAuction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface GrantAuction_grantAuction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface GrantAuction_grantAuction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface GrantAuction_grantAuction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: GrantAuction_grantAuction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: GrantAuction_grantAuction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: GrantAuction_grantAuction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: GrantAuction_grantAuction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: GrantAuction_grantAuction_grantedTo_bidderEntity | null;
}

export interface GrantAuction_grantAuction_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface GrantAuction_grantAuction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: GrantAuction_grantAuction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: GrantAuction_grantAuction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: GrantAuction_grantAuction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: GrantAuction_grantAuction_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: GrantAuction_grantAuction_asset;
}

export interface GrantAuction {
  /**
   * Grant an auction
   */
  grantAuction: GrantAuction_grantAuction;
}

export interface GrantAuctionVariables {
  id: string;
  bidId?: string | null;
  remark?: string | null;
  contact?: ContactBelongsTo | null;
  entity?: EntityBelongsTo | null;
  amount?: number | null;
  upload?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkAuctionAsPaid
// ====================================================

export interface MarkAuctionAsPaid_markAuctionAsPaid_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: MarkAuctionAsPaid_markAuctionAsPaid_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: MarkAuctionAsPaid_markAuctionAsPaid_bids_data_bidderEntity | null;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: MarkAuctionAsPaid_markAuctionAsPaid_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: MarkAuctionAsPaid_markAuctionAsPaid_bids_data[];
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_approvals_approver;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo_bidderEntity | null;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface MarkAuctionAsPaid_markAuctionAsPaid {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: MarkAuctionAsPaid_markAuctionAsPaid_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: MarkAuctionAsPaid_markAuctionAsPaid_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: MarkAuctionAsPaid_markAuctionAsPaid_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: MarkAuctionAsPaid_markAuctionAsPaid_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: MarkAuctionAsPaid_markAuctionAsPaid_asset;
}

export interface MarkAuctionAsPaid {
  /**
   * Mark the auction as paid
   */
  markAuctionAsPaid: MarkAuctionAsPaid_markAuctionAsPaid;
}

export interface MarkAuctionAsPaidVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestAuctionGrantApproval
// ====================================================

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data_bidderEntity | null;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: RequestAuctionGrantApproval_requestAuctionGrantApproval_bids_data[];
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_approvals_approver;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo_bidderEntity | null;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface RequestAuctionGrantApproval_requestAuctionGrantApproval {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: RequestAuctionGrantApproval_requestAuctionGrantApproval_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: RequestAuctionGrantApproval_requestAuctionGrantApproval_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: RequestAuctionGrantApproval_requestAuctionGrantApproval_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: RequestAuctionGrantApproval_requestAuctionGrantApproval_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: RequestAuctionGrantApproval_requestAuctionGrantApproval_asset;
}

export interface RequestAuctionGrantApproval {
  /**
   * Send an approval request for an auction grant
   */
  requestAuctionGrantApproval: RequestAuctionGrantApproval_requestAuctionGrantApproval;
}

export interface RequestAuctionGrantApprovalVariables {
  id: string;
  user: UserBelongsTo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAuction
// ====================================================

export interface UpdateAuction_updateAuction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface UpdateAuction_updateAuction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface UpdateAuction_updateAuction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface UpdateAuction_updateAuction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UpdateAuction_updateAuction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: UpdateAuction_updateAuction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: UpdateAuction_updateAuction_bids_data_bidderEntity | null;
}

export interface UpdateAuction_updateAuction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: UpdateAuction_updateAuction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: UpdateAuction_updateAuction_bids_data[];
}

export interface UpdateAuction_updateAuction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface UpdateAuction_updateAuction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface UpdateAuction_updateAuction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: UpdateAuction_updateAuction_grantedTo_approvals_approver;
}

export interface UpdateAuction_updateAuction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface UpdateAuction_updateAuction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface UpdateAuction_updateAuction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface UpdateAuction_updateAuction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UpdateAuction_updateAuction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: UpdateAuction_updateAuction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: UpdateAuction_updateAuction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: UpdateAuction_updateAuction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: UpdateAuction_updateAuction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: UpdateAuction_updateAuction_grantedTo_bidderEntity | null;
}

export interface UpdateAuction_updateAuction_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface UpdateAuction_updateAuction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: UpdateAuction_updateAuction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: UpdateAuction_updateAuction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: UpdateAuction_updateAuction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: UpdateAuction_updateAuction_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: UpdateAuction_updateAuction_asset;
}

export interface UpdateAuction {
  /**
   * Update an auction
   */
  updateAuction: UpdateAuction_updateAuction;
}

export interface UpdateAuctionVariables {
  auction: UpdateAuctionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadPaymentDocument
// ====================================================

export interface UploadPaymentDocument_uploadPaymentDocument_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface UploadPaymentDocument_uploadPaymentDocument_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface UploadPaymentDocument_uploadPaymentDocument_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: UploadPaymentDocument_uploadPaymentDocument_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: UploadPaymentDocument_uploadPaymentDocument_bids_data_bidderEntity | null;
}

export interface UploadPaymentDocument_uploadPaymentDocument_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: UploadPaymentDocument_uploadPaymentDocument_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: UploadPaymentDocument_uploadPaymentDocument_bids_data[];
}

export interface UploadPaymentDocument_uploadPaymentDocument_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: UploadPaymentDocument_uploadPaymentDocument_grantedTo_approvals_approver;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: UploadPaymentDocument_uploadPaymentDocument_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: UploadPaymentDocument_uploadPaymentDocument_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: UploadPaymentDocument_uploadPaymentDocument_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: UploadPaymentDocument_uploadPaymentDocument_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: UploadPaymentDocument_uploadPaymentDocument_grantedTo_bidderEntity | null;
}

export interface UploadPaymentDocument_uploadPaymentDocument_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface UploadPaymentDocument_uploadPaymentDocument {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: UploadPaymentDocument_uploadPaymentDocument_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: UploadPaymentDocument_uploadPaymentDocument_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: UploadPaymentDocument_uploadPaymentDocument_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: UploadPaymentDocument_uploadPaymentDocument_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: UploadPaymentDocument_uploadPaymentDocument_asset;
}

export interface UploadPaymentDocument {
  /**
   * Mark the auction as paid
   */
  uploadPaymentDocument: UploadPaymentDocument_uploadPaymentDocument;
}

export interface UploadPaymentDocumentVariables {
  id: string;
  upload: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PostponeInspectionPlanning
// ====================================================

export interface PostponeInspectionPlanning_postponeInspectionPlanning_address_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface PostponeInspectionPlanning_postponeInspectionPlanning_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: PostponeInspectionPlanning_postponeInspectionPlanning_address_entity;
}

export interface PostponeInspectionPlanning_postponeInspectionPlanning {
  __typename: "InspectionPlanning";
  /**
   * The inspection planning type identifier
   */
  id: string;
  /**
   * The address where the planning is about
   */
  address: PostponeInspectionPlanning_postponeInspectionPlanning_address | null;
  /**
   * When the last inspection is
   */
  previousDate: any | null;
  /**
   * When the next inspection is
   */
  nextDate: any;
  /**
   * The frequency of the inspections
   */
  frequency: number | null;
  /**
   * If the inspections must be trusted
   */
  trusted: boolean;
  /**
   * The duration of the inspection in days
   */
  duration: number;
  /**
   * When this will be automatically planned
   */
  automaticPlannedAt: any | null;
  /**
   * The number of assets
   */
  amountOfAssets: number;
  /**
   * The percentage of assets of the next audit
   */
  percentageOfAssets: number;
}

export interface PostponeInspectionPlanning {
  postponeInspectionPlanning: PostponeInspectionPlanning_postponeInspectionPlanning;
}

export interface PostponeInspectionPlanningVariables {
  id: string;
  postponedTo: any;
  reason: PostponeReason;
  rating?: AuditRating | null;
  frequency?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface CreateUser_createUser_addresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface CreateUser_createUser_addresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: CreateUser_createUser_addresses_entity;
}

export interface CreateUser_createUser_roles {
  __typename: "Role";
  /**
   * The identifier of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface CreateUser_createUser_device {
  __typename: "Device";
  /**
   * The name of the device
   */
  name: string;
  /**
   * The version used on the device is outdated
   */
  outdated: boolean;
  /**
   * The version used on the device
   */
  appVersion: string | null;
  /**
   * The version used on the device is outdated
   */
  appOutdated: boolean;
  /**
   * The os on the device
   */
  systemOs: string | null;
  /**
   * The sdk version on the device
   */
  systemSdk: string | null;
  /**
   * The os used on the device is outdated
   */
  systemOsOutdated: boolean;
}

export interface CreateUser_createUser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The full name field of user
   */
  name: string;
  /**
   * The full name field of user
   */
  displayName: string | null;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The first name of the user
   */
  firstName: string | null;
  /**
   * The last name of the user
   */
  lastName: string | null;
  /**
   * The phone number of the user
   */
  phone: string | null;
  /**
   * The display language of the user
   */
  language: string | null;
  /**
   * Indicator whether the user has set a password and is activated
   */
  hasPassword: boolean;
  /**
   * The entity the user belongs to
   */
  entity: CreateUser_createUser_entity;
  /**
   * The addresses that belongs to the user
   */
  addresses: CreateUser_createUser_addresses[];
  /**
   * The roles the user owns
   */
  roles: CreateUser_createUser_roles[];
  /**
   * The device used by the user
   */
  device: CreateUser_createUser_device | null;
  /**
   * The inspections that belongs to the asset
   */
  openInspectionsAmount: number | null;
  /**
   * The last inspection that belongs to the asset
   */
  inspectionsAmount: number | null;
  hasBidRights: boolean | null;
}

export interface CreateUser {
  createUser: CreateUser_createUser | null;
}

export interface CreateUserVariables {
  user: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface UpdateUser_updateUser_addresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface UpdateUser_updateUser_addresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: UpdateUser_updateUser_addresses_entity;
}

export interface UpdateUser_updateUser_roles {
  __typename: "Role";
  /**
   * The identifier of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface UpdateUser_updateUser_device {
  __typename: "Device";
  /**
   * The name of the device
   */
  name: string;
  /**
   * The version used on the device is outdated
   */
  outdated: boolean;
  /**
   * The version used on the device
   */
  appVersion: string | null;
  /**
   * The version used on the device is outdated
   */
  appOutdated: boolean;
  /**
   * The os on the device
   */
  systemOs: string | null;
  /**
   * The sdk version on the device
   */
  systemSdk: string | null;
  /**
   * The os used on the device is outdated
   */
  systemOsOutdated: boolean;
}

export interface UpdateUser_updateUser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The full name field of user
   */
  name: string;
  /**
   * The full name field of user
   */
  displayName: string | null;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The first name of the user
   */
  firstName: string | null;
  /**
   * The last name of the user
   */
  lastName: string | null;
  /**
   * The phone number of the user
   */
  phone: string | null;
  /**
   * The display language of the user
   */
  language: string | null;
  /**
   * Indicator whether the user has set a password and is activated
   */
  hasPassword: boolean;
  /**
   * The entity the user belongs to
   */
  entity: UpdateUser_updateUser_entity;
  /**
   * The addresses that belongs to the user
   */
  addresses: UpdateUser_updateUser_addresses[];
  /**
   * The roles the user owns
   */
  roles: UpdateUser_updateUser_roles[];
  /**
   * The device used by the user
   */
  device: UpdateUser_updateUser_device | null;
  /**
   * The inspections that belongs to the asset
   */
  openInspectionsAmount: number | null;
  /**
   * The last inspection that belongs to the asset
   */
  inspectionsAmount: number | null;
  hasBidRights: boolean | null;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser | null;
}

export interface UpdateUserVariables {
  user: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppraisalTypes
// ====================================================

export interface AppraisalTypes_appraisalTypes {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
  /**
   * If you must fill in the value
   */
  valueRequired: boolean;
}

export interface AppraisalTypes {
  /**
   * Fetch the contents of many appraisal type at once
   */
  appraisalTypes: AppraisalTypes_appraisalTypes[];
}

export interface AppraisalTypesVariables {
  valueRequired?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateAssets
// ====================================================

export interface PaginateAssets_paginateAssets_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateAssets_paginateAssets_data_status {
  __typename: "AssetStatus";
  id: string;
  name: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: PaginateAssets_paginateAssets_data_lastAuction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: PaginateAssets_paginateAssets_data_lastAuction_bids_data_bidderEntity | null;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateAssets_paginateAssets_data_lastAuction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: PaginateAssets_paginateAssets_data_lastAuction_bids_data[];
}

export interface PaginateAssets_paginateAssets_data_lastAuction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_approvals_approver;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_lastAuction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: PaginateAssets_paginateAssets_data_lastAuction_grantedTo_bidderEntity | null;
}

export interface PaginateAssets_paginateAssets_data_lastAuction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: PaginateAssets_paginateAssets_data_lastAuction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: PaginateAssets_paginateAssets_data_lastAuction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: PaginateAssets_paginateAssets_data_lastAuction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: PaginateAssets_paginateAssets_data_lastAuction_grantedTo | null;
}

export interface PaginateAssets_paginateAssets_data_lastInspection_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
}

export interface PaginateAssets_paginateAssets_data_lastInspection_flow {
  __typename: "Flow";
  /**
   * The id of the flow
   */
  id: string;
}

export interface PaginateAssets_paginateAssets_data_lastInspection_parameterValues {
  __typename: "ParameterValue";
  /**
   * The identifier of the parameter value
   */
  id: string;
}

export interface PaginateAssets_paginateAssets_data_lastInspection_address_dealer {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface PaginateAssets_paginateAssets_data_lastInspection_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The dealer of the address
   */
  dealer: PaginateAssets_paginateAssets_data_lastInspection_address_dealer | null;
}

export interface PaginateAssets_paginateAssets_data_lastInspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The situation of the equipment when an inspection is finished
   */
  equipmentSituation: InspectionEquipmentSituation | null;
  /**
   * The timestamp the inspection is planned and becomes available to the inspector
   */
  plannedAt: any | null;
  /**
   * The timestamp the inspection should be finished and will become unavailable to
   * the inspector, the deadline of the inspection
   */
  dueAt: any | null;
  /**
   * The timestamp the inspection was submitted
   */
  submittedAt: any | null;
  /**
   * The asset the inspection should be perfomed on
   */
  asset: PaginateAssets_paginateAssets_data_lastInspection_asset;
  /**
   * The flow information of this inspection
   */
  flow: PaginateAssets_paginateAssets_data_lastInspection_flow | null;
  /**
   * The values gathered for the parameters attached to the inspection when the inspection was executed
   */
  parameterValues: PaginateAssets_paginateAssets_data_lastInspection_parameterValues[];
  /**
   * The physical address this inspection should take place
   */
  address: PaginateAssets_paginateAssets_data_lastInspection_address | null;
}

export interface PaginateAssets_paginateAssets_data_appraisals_appraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_appraisals_inspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
}

export interface PaginateAssets_paginateAssets_data_appraisals_appraiser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_appraisals_valuationAgency {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateAssets_paginateAssets_data_appraisals_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface PaginateAssets_paginateAssets_data_appraisals {
  __typename: "Appraisal";
  /**
   * The identifier of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: number | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: PaginateAssets_paginateAssets_data_appraisals_appraisalType;
  /**
   * The inspection that belongs to the appraisal
   */
  inspection: PaginateAssets_paginateAssets_data_appraisals_inspection;
  /**
   * The appraiser that belongs to the appraisal
   */
  appraiser: PaginateAssets_paginateAssets_data_appraisals_appraiser | null;
  /**
   * The valuation agency that belongs to the appraisal
   */
  valuationAgency: PaginateAssets_paginateAssets_data_appraisals_valuationAgency | null;
  /**
   * The appraisal report
   */
  report: PaginateAssets_paginateAssets_data_appraisals_report | null;
}

export interface PaginateAssets_paginateAssets_data {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
  /**
   * The serial number of the asset, this is used to identify single assets within the entire system
   */
  serialNumber: string | null;
  /**
   * The status of the asset
   */
  status: PaginateAssets_paginateAssets_data_status | null;
  /**
   * The last auction that belongs to the asset
   */
  lastAuction: PaginateAssets_paginateAssets_data_lastAuction | null;
  /**
   * The last inspection that belongs to the asset
   */
  lastInspection: PaginateAssets_paginateAssets_data_lastInspection | null;
  /**
   * The appraisals of the asset
   */
  appraisals: PaginateAssets_paginateAssets_data_appraisals[];
}

export interface PaginateAssets_paginateAssets {
  __typename: "AssetPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateAssets_paginateAssets_paginatorInfo;
  /**
   * A list of Asset items.
   */
  data: PaginateAssets_paginateAssets_data[];
}

export interface PaginateAssets {
  /**
   * Fetch the conents of many asset at once
   */
  paginateAssets: PaginateAssets_paginateAssets | null;
}

export interface PaginateAssetsVariables {
  search?: string | null;
  orderBy?: PaginateAssetsOrderByOrderByClause[] | null;
  statuses?: string[] | null;
  inspectionStatus?: InspectionStatus[] | null;
  auctionStatus?: AuctionStatus[] | null;
  needsAppraisal?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateAssetStatuses
// ====================================================

export interface PaginateAssetStatuses_paginateAssetStatuses_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateAssetStatuses_paginateAssetStatuses_data {
  __typename: "AssetStatus";
  id: string;
  name: string;
}

export interface PaginateAssetStatuses_paginateAssetStatuses {
  __typename: "AssetStatusPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateAssetStatuses_paginateAssetStatuses_paginatorInfo;
  /**
   * A list of AssetStatus items.
   */
  data: PaginateAssetStatuses_paginateAssetStatuses_data[];
}

export interface PaginateAssetStatuses {
  /**
   * Fetch the contents of many asset statuses at once
   */
  paginateAssetStatuses: PaginateAssetStatuses_paginateAssetStatuses | null;
}

export interface PaginateAssetStatusesVariables {
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllAuctionTypes
// ====================================================

export interface AllAuctionTypes_auctionTypes {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface AllAuctionTypes {
  /**
   * Fetch all auction types
   */
  auctionTypes: AllAuctionTypes_auctionTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Auction
// ====================================================

export interface Auction_auction_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface Auction_auction_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface Auction_auction_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface Auction_auction_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface Auction_auction_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: Auction_auction_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: Auction_auction_bids_data_bidderEntity | null;
}

export interface Auction_auction_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: Auction_auction_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: Auction_auction_bids_data[];
}

export interface Auction_auction_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface Auction_auction_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface Auction_auction_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: Auction_auction_grantedTo_approvals_approver;
}

export interface Auction_auction_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface Auction_auction_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface Auction_auction_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface Auction_auction_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface Auction_auction_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: Auction_auction_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: Auction_auction_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: Auction_auction_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: Auction_auction_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: Auction_auction_grantedTo_bidderEntity | null;
}

export interface Auction_auction_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface Auction_auction {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: Auction_auction_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: Auction_auction_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: Auction_auction_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: Auction_auction_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: Auction_auction_asset;
}

export interface Auction {
  /**
   * Fetch the contents of a single auction
   */
  auction: Auction_auction;
}

export interface AuctionVariables {
  id?: string | null;
  assetId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateAuctions
// ====================================================

export interface PaginateAuctions_paginateAuctions_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateAuctions_paginateAuctions_data_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface PaginateAuctions_paginateAuctions_data_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateAuctions_paginateAuctions_data_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface PaginateAuctions_paginateAuctions_data_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateAuctions_paginateAuctions_data_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: PaginateAuctions_paginateAuctions_data_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: PaginateAuctions_paginateAuctions_data_bids_data_bidderEntity | null;
}

export interface PaginateAuctions_paginateAuctions_data_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateAuctions_paginateAuctions_data_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: PaginateAuctions_paginateAuctions_data_bids_data[];
}

export interface PaginateAuctions_paginateAuctions_data_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: PaginateAuctions_paginateAuctions_data_grantedTo_approvals_approver;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateAuctions_paginateAuctions_data_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: PaginateAuctions_paginateAuctions_data_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: PaginateAuctions_paginateAuctions_data_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: PaginateAuctions_paginateAuctions_data_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: PaginateAuctions_paginateAuctions_data_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: PaginateAuctions_paginateAuctions_data_grantedTo_bidderEntity | null;
}

export interface PaginateAuctions_paginateAuctions_data {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: PaginateAuctions_paginateAuctions_data_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: PaginateAuctions_paginateAuctions_data_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: PaginateAuctions_paginateAuctions_data_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: PaginateAuctions_paginateAuctions_data_grantedTo | null;
}

export interface PaginateAuctions_paginateAuctions {
  __typename: "AuctionPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateAuctions_paginateAuctions_paginatorInfo;
  /**
   * A list of Auction items.
   */
  data: PaginateAuctions_paginateAuctions_data[];
}

export interface PaginateAuctions {
  /**
   * Fetch the contents of many auction at once
   */
  paginateAuctions: PaginateAuctions_paginateAuctions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Dealer
// ====================================================

export interface Dealer_entity_dealerAddresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface Dealer_entity_dealerAddresses_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface Dealer_entity_dealerAddresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: Dealer_entity_dealerAddresses_entity;
  /**
   * When the next audit is of the address
   */
  nextAudit: any | null;
  /**
   * The frequency of the audits of the address
   */
  auditFrequency: number | null;
  /**
   * The identifier of the inspection in an external (third party) system
   */
  externalId: string | null;
  meta: Dealer_entity_dealerAddresses_meta[];
}

export interface Dealer_entity_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface Dealer_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
  /**
   * The external id of a dealer
   */
  dealerExternalId: string | null;
  /**
   * The dealer addresses
   */
  dealerAddresses: Dealer_entity_dealerAddresses[];
  meta: Dealer_entity_meta[];
}

export interface Dealer_paginateAudits_statistics_byRating {
  __typename: "AuditStatisticsByRatingPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface Dealer_paginateAudits_statistics_byStatus {
  __typename: "AuditStatisticsByStatusPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditStatus;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface Dealer_paginateAudits_statistics_ratingByAddress_partitions {
  __typename: "AuditStatisticsRatingByAddressPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface Dealer_paginateAudits_statistics_ratingByAddress {
  __typename: "AuditStatisticsRatingByAddressGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: Dealer_paginateAudits_statistics_ratingByAddress_partitions[];
}

export interface Dealer_paginateAudits_statistics {
  __typename: "AuditStatistics";
  /**
   * The amount of audits grouped by rating
   */
  byRating: Dealer_paginateAudits_statistics_byRating[];
  /**
   * The amount of audits grouped by status
   */
  byStatus: Dealer_paginateAudits_statistics_byStatus[];
  /**
   * The amount of audits grouped by rating an address
   */
  ratingByAddress: Dealer_paginateAudits_statistics_ratingByAddress[];
}

export interface Dealer_paginateAudits {
  __typename: "AuditPaginator";
  /**
   * The statistics of AuditPaginator
   */
  statistics: Dealer_paginateAudits_statistics;
}

export interface Dealer_paginateInspections_statistics_statusByMonth_partitions {
  __typename: "InspectionStatisticsStatusByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionStatus | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface Dealer_paginateInspections_statistics_statusByMonth {
  __typename: "InspectionStatisticsStatusByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: Dealer_paginateInspections_statistics_statusByMonth_partitions[];
}

export interface Dealer_paginateInspections_statistics_equipmentSituationByMonth_partitions {
  __typename: "InspectionStatisticsEquipmentSituationByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionEquipmentSituation | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface Dealer_paginateInspections_statistics_equipmentSituationByMonth {
  __typename: "InspectionStatisticsEquipmentSituationByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: Dealer_paginateInspections_statistics_equipmentSituationByMonth_partitions[];
}

export interface Dealer_paginateInspections_statistics {
  __typename: "InspectionStatistics";
  /**
   * The amount of inspections grouped by status an month
   */
  statusByMonth: Dealer_paginateInspections_statistics_statusByMonth[];
  /**
   * The amount of inspections grouped by equipment situtation an month
   */
  equipmentSituationByMonth: Dealer_paginateInspections_statistics_equipmentSituationByMonth[];
}

export interface Dealer_paginateInspections {
  __typename: "InspectionPaginator";
  /**
   * The statistics of InspectionPaginator
   */
  statistics: Dealer_paginateInspections_statistics;
}

export interface Dealer {
  entity: Dealer_entity;
  /**
   * Fetch the contents of multiple audits at once
   */
  paginateAudits: Dealer_paginateAudits | null;
  /**
   * Fetch the contents of many inspections at once
   */
  paginateInspections: Dealer_paginateInspections | null;
}

export interface DealerVariables {
  id: string;
  date?: DateRange | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DealerAddress
// ====================================================

export interface DealerAddress_entity_dealerAddresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface DealerAddress_entity_dealerAddresses_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerAddress_entity_dealerAddresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: DealerAddress_entity_dealerAddresses_entity;
  /**
   * When the next audit is of the address
   */
  nextAudit: any | null;
  /**
   * The frequency of the audits of the address
   */
  auditFrequency: number | null;
  /**
   * The identifier of the inspection in an external (third party) system
   */
  externalId: string | null;
  meta: DealerAddress_entity_dealerAddresses_meta[];
}

export interface DealerAddress_entity_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerAddress_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
  /**
   * The external id of a dealer
   */
  dealerExternalId: string | null;
  /**
   * The dealer addresses
   */
  dealerAddresses: DealerAddress_entity_dealerAddresses[];
  meta: DealerAddress_entity_meta[];
}

export interface DealerAddress_address_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface DealerAddress_address_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerAddress_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: DealerAddress_address_entity;
  /**
   * When the next audit is of the address
   */
  nextAudit: any | null;
  /**
   * The frequency of the audits of the address
   */
  auditFrequency: number | null;
  /**
   * The identifier of the inspection in an external (third party) system
   */
  externalId: string | null;
  meta: DealerAddress_address_meta[];
}

export interface DealerAddress_paginateAudits_statistics_byRating {
  __typename: "AuditStatisticsByRatingPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAddress_paginateAudits_statistics_byStatus {
  __typename: "AuditStatisticsByStatusPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditStatus;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAddress_paginateAudits_statistics_ratingByAddress_partitions {
  __typename: "AuditStatisticsRatingByAddressPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAddress_paginateAudits_statistics_ratingByAddress {
  __typename: "AuditStatisticsRatingByAddressGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerAddress_paginateAudits_statistics_ratingByAddress_partitions[];
}

export interface DealerAddress_paginateAudits_statistics {
  __typename: "AuditStatistics";
  /**
   * The amount of audits grouped by rating
   */
  byRating: DealerAddress_paginateAudits_statistics_byRating[];
  /**
   * The amount of audits grouped by status
   */
  byStatus: DealerAddress_paginateAudits_statistics_byStatus[];
  /**
   * The amount of audits grouped by rating an address
   */
  ratingByAddress: DealerAddress_paginateAudits_statistics_ratingByAddress[];
}

export interface DealerAddress_paginateAudits {
  __typename: "AuditPaginator";
  /**
   * The statistics of AuditPaginator
   */
  statistics: DealerAddress_paginateAudits_statistics;
}

export interface DealerAddress_paginateInspections_statistics_statusByMonth_partitions {
  __typename: "InspectionStatisticsStatusByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionStatus | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAddress_paginateInspections_statistics_statusByMonth {
  __typename: "InspectionStatisticsStatusByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerAddress_paginateInspections_statistics_statusByMonth_partitions[];
}

export interface DealerAddress_paginateInspections_statistics_equipmentSituationByMonth_partitions {
  __typename: "InspectionStatisticsEquipmentSituationByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionEquipmentSituation | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAddress_paginateInspections_statistics_equipmentSituationByMonth {
  __typename: "InspectionStatisticsEquipmentSituationByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerAddress_paginateInspections_statistics_equipmentSituationByMonth_partitions[];
}

export interface DealerAddress_paginateInspections_statistics {
  __typename: "InspectionStatistics";
  /**
   * The amount of inspections grouped by status an month
   */
  statusByMonth: DealerAddress_paginateInspections_statistics_statusByMonth[];
  /**
   * The amount of inspections grouped by equipment situtation an month
   */
  equipmentSituationByMonth: DealerAddress_paginateInspections_statistics_equipmentSituationByMonth[];
}

export interface DealerAddress_paginateInspections {
  __typename: "InspectionPaginator";
  /**
   * The statistics of InspectionPaginator
   */
  statistics: DealerAddress_paginateInspections_statistics;
}

export interface DealerAddress {
  entity: DealerAddress_entity;
  /**
   * Fetch the contents of many addresses at once
   */
  address: DealerAddress_address | null;
  /**
   * Fetch the contents of multiple audits at once
   */
  paginateAudits: DealerAddress_paginateAudits | null;
  /**
   * Fetch the contents of many inspections at once
   */
  paginateInspections: DealerAddress_paginateInspections | null;
}

export interface DealerAddressVariables {
  id: string;
  addressId: string;
  date?: DateRange | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateEntities
// ====================================================

export interface PaginateEntities_paginateEntities_data {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateEntities_paginateEntities {
  __typename: "EntityPaginator";
  /**
   * A list of Entity items.
   */
  data: PaginateEntities_paginateEntities_data[];
}

export interface PaginateEntities {
  /**
   * Fethd entities that are connect to you account
   */
  paginateEntities: PaginateEntities_paginateEntities | null;
}

export interface PaginateEntitiesVariables {
  search?: string | null;
  limit?: number | null;
  entityTypes?: EntityType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DetailFlow
// ====================================================

export interface DetailFlow_entityFlow_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface DetailFlow_entityFlow_group {
  __typename: "Group";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group, e.g. Mini Excavator, Tracked Excavator, etc.
   */
  name: string;
}

export interface DetailFlow_entityFlow_inspectionType {
  __typename: "InspectionType";
  /**
   * The inspection type identifier
   */
  id: string;
  /**
   * The inspection type name
   */
  name: string;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameterGroup {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_draft_parameterCategories_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The display type of the flow parameter group
   */
  displayType: DisplayType | null;
  /**
   * How the parameter group must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter group will be displayed
   */
  order: number | null;
  /**
   * The parameter group of the flow paramteer group
   */
  parameterGroup: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameterGroup | null;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The flow parameters in the flow parameter group
   */
  parameters: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups_parameters[];
  /**
   * The date of the flow parameter group when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter group when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_draft_parameterCategories {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The display type of the flow parameter category
   */
  displayType: DisplayType | null;
  /**
   * How the parameter category must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter category will be displayed
   */
  order: number | null;
  /**
   * The parameter category of the flow parameter category
   */
  parameterCategory: DetailFlow_entityFlow_draft_parameterCategories_parameterCategory;
  /**
   * The name of the flow parameter category
   */
  name: string;
  /**
   * The flow parameter groups in the flow parameter category
   */
  parameterGroups: DetailFlow_entityFlow_draft_parameterCategories_parameterGroups[];
  /**
   * The date of the flow parameter category when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter category when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_draft {
  __typename: "Flow";
  /**
   * The id of the flow
   */
  id: string;
  /**
   * The flow parameter categories in the flow
   */
  parameterCategories: DetailFlow_entityFlow_draft_parameterCategories[];
  /**
   * The display type of the flow
   */
  displayType: DisplayType | null;
  /**
   * The date of the flow when it was created
   */
  createdAt: any;
  /**
   * The date of the flow when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameterGroup {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_published_parameterCategories_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The display type of the flow parameter group
   */
  displayType: DisplayType | null;
  /**
   * How the parameter group must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter group will be displayed
   */
  order: number | null;
  /**
   * The parameter group of the flow paramteer group
   */
  parameterGroup: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameterGroup | null;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The flow parameters in the flow parameter group
   */
  parameters: DetailFlow_entityFlow_published_parameterCategories_parameterGroups_parameters[];
  /**
   * The date of the flow parameter group when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter group when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_published_parameterCategories {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The display type of the flow parameter category
   */
  displayType: DisplayType | null;
  /**
   * How the parameter category must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter category will be displayed
   */
  order: number | null;
  /**
   * The parameter category of the flow parameter category
   */
  parameterCategory: DetailFlow_entityFlow_published_parameterCategories_parameterCategory;
  /**
   * The name of the flow parameter category
   */
  name: string;
  /**
   * The flow parameter groups in the flow parameter category
   */
  parameterGroups: DetailFlow_entityFlow_published_parameterCategories_parameterGroups[];
  /**
   * The date of the flow parameter category when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter category when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow_published {
  __typename: "Flow";
  /**
   * The id of the flow
   */
  id: string;
  /**
   * The flow parameter categories in the flow
   */
  parameterCategories: DetailFlow_entityFlow_published_parameterCategories[];
  /**
   * The display type of the flow
   */
  displayType: DisplayType | null;
  /**
   * The date of the flow when it was created
   */
  createdAt: any;
  /**
   * The date of the flow when it was last updated
   */
  updatedAt: any | null;
}

export interface DetailFlow_entityFlow {
  __typename: "EntityFlow";
  id: string;
  /**
   *  Owning entity of this EntityFlow 
   */
  entity: DetailFlow_entityFlow_entity;
  /**
   *  Group selection for the managed flows 
   */
  group: DetailFlow_entityFlow_group | null;
  /**
   *  Type of inspection for which the managed flows are used 
   */
  inspectionType: DetailFlow_entityFlow_inspectionType;
  /**
   *  Draft of a flow waiting to be published 
   */
  draft: DetailFlow_entityFlow_draft | null;
  /**
   *  Published read only flow which is assigned to new inspections 
   */
  published: DetailFlow_entityFlow_published | null;
  createdAt: any;
  updatedAt: any | null;
  deletedAt: any | null;
}

export interface DetailFlow {
  entityFlow: DetailFlow_entityFlow;
}

export interface DetailFlowVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateEntityFlows
// ====================================================

export interface PaginateEntityFlows_myEntityFlows_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateEntityFlows_myEntityFlows_data_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateEntityFlows_myEntityFlows_data_group {
  __typename: "Group";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group, e.g. Mini Excavator, Tracked Excavator, etc.
   */
  name: string;
}

export interface PaginateEntityFlows_myEntityFlows_data_inspectionType {
  __typename: "InspectionType";
  /**
   * The inspection type identifier
   */
  id: string;
  /**
   * The inspection type name
   */
  name: string;
}

export interface PaginateEntityFlows_myEntityFlows_data {
  __typename: "EntityFlow";
  id: string;
  /**
   *  Owning entity of this EntityFlow 
   */
  entity: PaginateEntityFlows_myEntityFlows_data_entity;
  /**
   *  Group selection for the managed flows 
   */
  group: PaginateEntityFlows_myEntityFlows_data_group | null;
  /**
   *  Type of inspection for which the managed flows are used 
   */
  inspectionType: PaginateEntityFlows_myEntityFlows_data_inspectionType;
  /**
   *  DateTime when the current published flow was published 
   */
  publishedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  deletedAt: any | null;
}

export interface PaginateEntityFlows_myEntityFlows {
  __typename: "EntityFlowPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateEntityFlows_myEntityFlows_paginatorInfo;
  /**
   * A list of EntityFlow items.
   */
  data: PaginateEntityFlows_myEntityFlows_data[];
}

export interface PaginateEntityFlows {
  myEntityFlows: PaginateEntityFlows_myEntityFlows | null;
}

export interface PaginateEntityFlowsVariables {
  groups?: string[] | null;
  inspectionTypes?: string[] | null;
  orderBy?: MyEntityFlowsOrderByOrderByClause[] | null;
  first?: number | null;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateInspectionPlannings
// ====================================================

export interface PaginateInspectionPlannings_paginateInspectionPlannings_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateInspectionPlannings_paginateInspectionPlannings_data_address_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface PaginateInspectionPlannings_paginateInspectionPlannings_data_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: PaginateInspectionPlannings_paginateInspectionPlannings_data_address_entity;
}

export interface PaginateInspectionPlannings_paginateInspectionPlannings_data {
  __typename: "InspectionPlanning";
  /**
   * The inspection planning type identifier
   */
  id: string;
  /**
   * The address where the planning is about
   */
  address: PaginateInspectionPlannings_paginateInspectionPlannings_data_address | null;
  /**
   * When the last inspection is
   */
  previousDate: any | null;
  /**
   * When the next inspection is
   */
  nextDate: any;
  /**
   * The frequency of the inspections
   */
  frequency: number | null;
  /**
   * If the inspections must be trusted
   */
  trusted: boolean;
  /**
   * The duration of the inspection in days
   */
  duration: number;
  /**
   * When this will be automatically planned
   */
  automaticPlannedAt: any | null;
  /**
   * The number of assets
   */
  amountOfAssets: number;
  /**
   * The percentage of assets of the next audit
   */
  percentageOfAssets: number;
}

export interface PaginateInspectionPlannings_paginateInspectionPlannings {
  __typename: "InspectionPlanningPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateInspectionPlannings_paginateInspectionPlannings_paginatorInfo;
  /**
   * A list of InspectionPlanning items.
   */
  data: PaginateInspectionPlannings_paginateInspectionPlannings_data[];
}

export interface PaginateInspectionPlannings {
  /**
   * Fetch the contents of many inspection planning at once
   */
  paginateInspectionPlannings: PaginateInspectionPlannings_paginateInspectionPlannings | null;
}

export interface PaginateInspectionPlanningsVariables {
  first?: number | null;
  page?: number | null;
  nextDate?: DateRange | null;
  hasOpenAudits?: boolean | null;
  search?: string | null;
  orderBy?: PaginateInspectionPlanningsOrderByOrderByClause[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateNotifications
// ====================================================

export interface PaginateNotifications_paginateNotifications_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateNotifications_paginateNotifications_data_toUser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface PaginateNotifications_paginateNotifications_data {
  __typename: "Notification";
  /**
   * The identifier of the notification
   */
  id: string;
  /**
   * The name of the notification
   */
  name: string;
  /**
   * The user where the notification is send to
   */
  toUser: PaginateNotifications_paginateNotifications_data_toUser;
  /**
   * The timestamp the notification was set
   */
  sendAt: any;
}

export interface PaginateNotifications_paginateNotifications {
  __typename: "NotificationPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateNotifications_paginateNotifications_paginatorInfo;
  /**
   * A list of Notification items.
   */
  data: PaginateNotifications_paginateNotifications_data[];
}

export interface PaginateNotifications {
  paginateNotifications: PaginateNotifications_paginateNotifications | null;
}

export interface PaginateNotificationsVariables {
  first?: number | null;
  page?: number | null;
  toUsers?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateUsers
// ====================================================

export interface PaginateUsers_paginateUsers_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PaginateUsers_paginateUsers_data_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface PaginateUsers_paginateUsers_data_addresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface PaginateUsers_paginateUsers_data_addresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: PaginateUsers_paginateUsers_data_addresses_entity;
}

export interface PaginateUsers_paginateUsers_data_roles {
  __typename: "Role";
  /**
   * The identifier of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface PaginateUsers_paginateUsers_data_device {
  __typename: "Device";
  /**
   * The name of the device
   */
  name: string;
  /**
   * The version used on the device is outdated
   */
  outdated: boolean;
  /**
   * The version used on the device
   */
  appVersion: string | null;
  /**
   * The version used on the device is outdated
   */
  appOutdated: boolean;
  /**
   * The os on the device
   */
  systemOs: string | null;
  /**
   * The sdk version on the device
   */
  systemSdk: string | null;
  /**
   * The os used on the device is outdated
   */
  systemOsOutdated: boolean;
}

export interface PaginateUsers_paginateUsers_data {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The full name field of user
   */
  name: string;
  /**
   * The full name field of user
   */
  displayName: string | null;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The first name of the user
   */
  firstName: string | null;
  /**
   * The last name of the user
   */
  lastName: string | null;
  /**
   * The phone number of the user
   */
  phone: string | null;
  /**
   * The display language of the user
   */
  language: string | null;
  /**
   * Indicator whether the user has set a password and is activated
   */
  hasPassword: boolean;
  /**
   * The entity the user belongs to
   */
  entity: PaginateUsers_paginateUsers_data_entity;
  /**
   * The addresses that belongs to the user
   */
  addresses: PaginateUsers_paginateUsers_data_addresses[];
  /**
   * The roles the user owns
   */
  roles: PaginateUsers_paginateUsers_data_roles[];
  /**
   * The device used by the user
   */
  device: PaginateUsers_paginateUsers_data_device | null;
  /**
   * The inspections that belongs to the asset
   */
  openInspectionsAmount: number | null;
  /**
   * The last inspection that belongs to the asset
   */
  inspectionsAmount: number | null;
  hasBidRights: boolean | null;
}

export interface PaginateUsers_paginateUsers {
  __typename: "UserPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: PaginateUsers_paginateUsers_paginatorInfo;
  /**
   * A list of User items.
   */
  data: PaginateUsers_paginateUsers_data[];
}

export interface PaginateUsers {
  /**
   * Fetch users that are connect to you account
   */
  paginateUsers: PaginateUsers_paginateUsers | null;
}

export interface PaginateUsersVariables {
  limit?: number | null;
  page?: number | null;
  search?: string | null;
  orderBy?: PaginateUsersOrderByOrderByClause[] | null;
  entityTypes?: EntityType[] | null;
  entityIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchUsers
// ====================================================

export interface SearchUsers_paginateUsers_data {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The full name field of user
   */
  name: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface SearchUsers_paginateUsers {
  __typename: "UserPaginator";
  /**
   * A list of User items.
   */
  data: SearchUsers_paginateUsers_data[];
}

export interface SearchUsers {
  /**
   * Fetch users that are connect to you account
   */
  paginateUsers: SearchUsers_paginateUsers | null;
}

export interface SearchUsersVariables {
  search?: string | null;
  limit?: number | null;
  entityTypes?: EntityType[] | null;
  entityIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressDetail
// ====================================================

export interface AddressDetail_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface AddressDetail {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: AddressDetail_entity;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressList
// ====================================================

export interface AddressList_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface AddressList {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: AddressList_entity;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Appraisal
// ====================================================

export interface Appraisal_appraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
}

export interface Appraisal_inspection {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
}

export interface Appraisal_appraiser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface Appraisal_valuationAgency {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface Appraisal_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface Appraisal {
  __typename: "Appraisal";
  /**
   * The identifier of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: number | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: Appraisal_appraisalType;
  /**
   * The inspection that belongs to the appraisal
   */
  inspection: Appraisal_inspection;
  /**
   * The appraiser that belongs to the appraisal
   */
  appraiser: Appraisal_appraiser | null;
  /**
   * The valuation agency that belongs to the appraisal
   */
  valuationAgency: Appraisal_valuationAgency | null;
  /**
   * The appraisal report
   */
  report: Appraisal_report | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppraisalType
// ====================================================

export interface AppraisalType {
  __typename: "AppraisalType";
  /**
   * The identifier of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
  /**
   * If you must fill in the value
   */
  valueRequired: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetList
// ====================================================

export interface AssetList_status {
  __typename: "AssetStatus";
  id: string;
  name: string;
}

export interface AssetList {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
  /**
   * The serial number of the asset, this is used to identify single assets within the entire system
   */
  serialNumber: string | null;
  /**
   * The status of the asset
   */
  status: AssetList_status | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetStatusList
// ====================================================

export interface AssetStatusList {
  __typename: "AssetStatus";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuctionDetail
// ====================================================

export interface AuctionDetail_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface AuctionDetail_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface AuctionDetail_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface AuctionDetail_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface AuctionDetail_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: AuctionDetail_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: AuctionDetail_bids_data_bidderEntity | null;
}

export interface AuctionDetail_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: AuctionDetail_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: AuctionDetail_bids_data[];
}

export interface AuctionDetail_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface AuctionDetail_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface AuctionDetail_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: AuctionDetail_grantedTo_approvals_approver;
}

export interface AuctionDetail_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface AuctionDetail_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface AuctionDetail_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface AuctionDetail_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface AuctionDetail_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: AuctionDetail_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: AuctionDetail_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: AuctionDetail_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: AuctionDetail_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: AuctionDetail_grantedTo_bidderEntity | null;
}

export interface AuctionDetail_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string;
}

export interface AuctionDetail {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: AuctionDetail_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: AuctionDetail_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: AuctionDetail_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: AuctionDetail_grantedTo | null;
  /**
   * The asset of the auction
   */
  asset: AuctionDetail_asset;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuctionList
// ====================================================

export interface AuctionList_auctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

export interface AuctionList_bids_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface AuctionList_bids_data_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
}

export interface AuctionList_bids_data_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface AuctionList_bids_data {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
  /**
   * The bidder of the bid
   */
  bidder: AuctionList_bids_data_bidder | null;
  /**
   * The bidder entity of the bid
   */
  bidderEntity: AuctionList_bids_data_bidderEntity | null;
}

export interface AuctionList_bids {
  __typename: "AuctionBidPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: AuctionList_bids_paginatorInfo;
  /**
   * A list of AuctionBid items.
   */
  data: AuctionList_bids_data[];
}

export interface AuctionList_highestBid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
}

export interface AuctionList_grantedTo_approvals_approver {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
}

export interface AuctionList_grantedTo_approvals {
  __typename: "AuctionGrantApproval";
  /**
   * The identifier of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  status: ApprovalStatus;
  /**
   * The remark of the approval
   */
  remark: string | null;
  /**
   * The approver of the approval
   */
  approver: AuctionList_grantedTo_approvals_approver;
}

export interface AuctionList_grantedTo_paymentDocument {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the media
   */
  url: string;
  /**
   * The file name of the media
   */
  fileName: string;
}

export interface AuctionList_grantedTo_bid {
  __typename: "AuctionBid";
  /**
   * The identifier of the bid
   */
  id: string;
  /**
   * The amount of the bid
   */
  amount: number;
  /**
   * The timestamp the auction bid was created
   */
  createdAt: any;
}

export interface AuctionList_grantedTo_bidder {
  __typename: "User" | "Contact";
  /**
   * The identifier of the person
   */
  id: string;
  /**
   * The full name field of person
   */
  name: string;
  /**
   * The email address of the person
   */
  email: string;
  /**
   * The phone number of the person
   */
  phone: string | null;
}

export interface AuctionList_grantedTo_bidderEntity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface AuctionList_grantedTo {
  __typename: "AuctionGrant";
  /**
   * The identifier of the grant
   */
  id: string;
  /**
   * The status of the auciton grant
   */
  status: GrantStatus;
  /**
   * If you have already approved the grant
   */
  youHaveApproved: boolean;
  /**
   * If you have to approve the grant
   */
  youHaveToApprove: boolean;
  /**
   * Approvals of the auction grant
   */
  approvals: AuctionList_grantedTo_approvals[];
  /**
   * The date when the bidder paid the auction
   */
  paidAt: any | null;
  /**
   * The document that describe the prove of payment
   */
  paymentDocument: AuctionList_grantedTo_paymentDocument | null;
  /**
   * The bid where the auction is granted to
   */
  bid: AuctionList_grantedTo_bid;
  /**
   * The bidder where the auction is granted to
   */
  bidder: AuctionList_grantedTo_bidder | null;
  /**
   * The bidder entity where the auction is granted to
   */
  bidderEntity: AuctionList_grantedTo_bidderEntity | null;
}

export interface AuctionList {
  __typename: "Auction";
  /**
   * The identifier of the auction
   */
  id: string;
  /**
   * The auction type of the auction
   */
  auctionType: AuctionList_auctionType;
  /**
   * The status of the auction
   */
  status: AuctionStatus;
  /**
   * When the auction will start
   */
  startingAt: any;
  /**
   * When the auction will end
   */
  endingAt: any;
  /**
   * The total amount of times viewed
   */
  totalViewed: number;
  /**
   * The url to the auction
   */
  auctionUrl: string | null;
  /**
   * The bids of the auction auction
   */
  bids: AuctionList_bids | null;
  /**
   * The highest bid of the auction auction
   */
  highestBid: AuctionList_highestBid | null;
  /**
   * To where the auction is granted to
   */
  grantedTo: AuctionList_grantedTo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuctionType
// ====================================================

export interface AuctionType {
  __typename: "AuctionType";
  /**
   * The identifier of the auction type
   */
  id: string;
  /**
   * The name of the auction type
   */
  name: string;
  /**
   * If bids are visible in the auction
   */
  bidsVisible: BidVisible;
  /**
   * If you must overbid in the auction
   */
  mustOverbid: boolean;
  /**
   * If the auction is preformed by an external auction
   */
  isExternal: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DealerAddressDetail
// ====================================================

export interface DealerAddressDetail_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface DealerAddressDetail_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerAddressDetail {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: DealerAddressDetail_entity;
  /**
   * When the next audit is of the address
   */
  nextAudit: any | null;
  /**
   * The frequency of the audits of the address
   */
  auditFrequency: number | null;
  /**
   * The identifier of the inspection in an external (third party) system
   */
  externalId: string | null;
  meta: DealerAddressDetail_meta[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DealerAuditStatistics
// ====================================================

export interface DealerAuditStatistics_byRating {
  __typename: "AuditStatisticsByRatingPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAuditStatistics_byStatus {
  __typename: "AuditStatisticsByStatusPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditStatus;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAuditStatistics_ratingByAddress_partitions {
  __typename: "AuditStatisticsRatingByAddressPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: AuditRating | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerAuditStatistics_ratingByAddress {
  __typename: "AuditStatisticsRatingByAddressGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerAuditStatistics_ratingByAddress_partitions[];
}

export interface DealerAuditStatistics {
  __typename: "AuditStatistics";
  /**
   * The amount of audits grouped by rating
   */
  byRating: DealerAuditStatistics_byRating[];
  /**
   * The amount of audits grouped by status
   */
  byStatus: DealerAuditStatistics_byStatus[];
  /**
   * The amount of audits grouped by rating an address
   */
  ratingByAddress: DealerAuditStatistics_ratingByAddress[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DealerDetail
// ====================================================

export interface DealerDetail_dealerAddresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface DealerDetail_dealerAddresses_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerDetail_dealerAddresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The first address line of the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country code of the address in ISO
   */
  countryCode: string | null;
  /**
   * The name of the country derived from the countryCode field
   */
  countryName: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: DealerDetail_dealerAddresses_entity;
  /**
   * When the next audit is of the address
   */
  nextAudit: any | null;
  /**
   * The frequency of the audits of the address
   */
  auditFrequency: number | null;
  /**
   * The identifier of the inspection in an external (third party) system
   */
  externalId: string | null;
  meta: DealerDetail_dealerAddresses_meta[];
}

export interface DealerDetail_meta {
  __typename: "Meta";
  id: string;
  key: string;
  value: string | null;
}

export interface DealerDetail {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
  /**
   * The external id of a dealer
   */
  dealerExternalId: string | null;
  /**
   * The dealer addresses
   */
  dealerAddresses: DealerDetail_dealerAddresses[];
  meta: DealerDetail_meta[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DealerInspectionStatistics
// ====================================================

export interface DealerInspectionStatistics_statusByMonth_partitions {
  __typename: "InspectionStatisticsStatusByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionStatus | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerInspectionStatistics_statusByMonth {
  __typename: "InspectionStatisticsStatusByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerInspectionStatistics_statusByMonth_partitions[];
}

export interface DealerInspectionStatistics_equipmentSituationByMonth_partitions {
  __typename: "InspectionStatisticsEquipmentSituationByMonthPartition";
  /**
   * The identifier of the partition
   */
  id: string;
  /**
   * The name of the partition
   */
  name: InspectionEquipmentSituation | null;
  /**
   * The amount of the partition
   */
  amount: number;
}

export interface DealerInspectionStatistics_equipmentSituationByMonth {
  __typename: "InspectionStatisticsEquipmentSituationByMonthGroup";
  /**
   * The identifier of the group
   */
  id: string;
  /**
   * The name of the group
   */
  name: string;
  /**
   * The partitions of the group
   */
  partitions: DealerInspectionStatistics_equipmentSituationByMonth_partitions[];
}

export interface DealerInspectionStatistics {
  __typename: "InspectionStatistics";
  /**
   * The amount of inspections grouped by status an month
   */
  statusByMonth: DealerInspectionStatistics_statusByMonth[];
  /**
   * The amount of inspections grouped by equipment situtation an month
   */
  equipmentSituationByMonth: DealerInspectionStatistics_equipmentSituationByMonth[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Flow
// ====================================================

export interface Flow_parameterCategories_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface Flow_parameterCategories_parameterGroups_parameterGroup {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface Flow_parameterCategories_parameterGroups_parameters_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface Flow_parameterCategories_parameterGroups_parameters_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface Flow_parameterCategories_parameterGroups_parameters_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface Flow_parameterCategories_parameterGroups_parameters_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: Flow_parameterCategories_parameterGroups_parameters_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: Flow_parameterCategories_parameterGroups_parameters_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: Flow_parameterCategories_parameterGroups_parameters_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface Flow_parameterCategories_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: Flow_parameterCategories_parameterGroups_parameters_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

export interface Flow_parameterCategories_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The display type of the flow parameter group
   */
  displayType: DisplayType | null;
  /**
   * How the parameter group must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter group will be displayed
   */
  order: number | null;
  /**
   * The parameter group of the flow paramteer group
   */
  parameterGroup: Flow_parameterCategories_parameterGroups_parameterGroup | null;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The flow parameters in the flow parameter group
   */
  parameters: Flow_parameterCategories_parameterGroups_parameters[];
  /**
   * The date of the flow parameter group when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter group when it was last updated
   */
  updatedAt: any | null;
}

export interface Flow_parameterCategories {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The display type of the flow parameter category
   */
  displayType: DisplayType | null;
  /**
   * How the parameter category must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter category will be displayed
   */
  order: number | null;
  /**
   * The parameter category of the flow parameter category
   */
  parameterCategory: Flow_parameterCategories_parameterCategory;
  /**
   * The name of the flow parameter category
   */
  name: string;
  /**
   * The flow parameter groups in the flow parameter category
   */
  parameterGroups: Flow_parameterCategories_parameterGroups[];
  /**
   * The date of the flow parameter category when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter category when it was last updated
   */
  updatedAt: any | null;
}

export interface Flow {
  __typename: "Flow";
  /**
   * The id of the flow
   */
  id: string;
  /**
   * The flow parameter categories in the flow
   */
  parameterCategories: Flow_parameterCategories[];
  /**
   * The display type of the flow
   */
  displayType: DisplayType | null;
  /**
   * The date of the flow when it was created
   */
  createdAt: any;
  /**
   * The date of the flow when it was last updated
   */
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowParameter
// ====================================================

export interface FlowParameter_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameter_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameter_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface FlowParameter_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: FlowParameter_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: FlowParameter_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: FlowParameter_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface FlowParameter {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: FlowParameter_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowParameterCategory
// ====================================================

export interface FlowParameterCategory_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameterCategory_parameterGroups_parameterGroup {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameterCategory_parameterGroups_parameters_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameterCategory_parameterGroups_parameters_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameterCategory_parameterGroups_parameters_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface FlowParameterCategory_parameterGroups_parameters_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: FlowParameterCategory_parameterGroups_parameters_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: FlowParameterCategory_parameterGroups_parameters_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: FlowParameterCategory_parameterGroups_parameters_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface FlowParameterCategory_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: FlowParameterCategory_parameterGroups_parameters_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameterCategory_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The display type of the flow parameter group
   */
  displayType: DisplayType | null;
  /**
   * How the parameter group must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter group will be displayed
   */
  order: number | null;
  /**
   * The parameter group of the flow paramteer group
   */
  parameterGroup: FlowParameterCategory_parameterGroups_parameterGroup | null;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The flow parameters in the flow parameter group
   */
  parameters: FlowParameterCategory_parameterGroups_parameters[];
  /**
   * The date of the flow parameter group when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter group when it was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameterCategory {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The display type of the flow parameter category
   */
  displayType: DisplayType | null;
  /**
   * How the parameter category must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter category will be displayed
   */
  order: number | null;
  /**
   * The parameter category of the flow parameter category
   */
  parameterCategory: FlowParameterCategory_parameterCategory;
  /**
   * The name of the flow parameter category
   */
  name: string;
  /**
   * The flow parameter groups in the flow parameter category
   */
  parameterGroups: FlowParameterCategory_parameterGroups[];
  /**
   * The date of the flow parameter category when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter category when it was last updated
   */
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowParameterGroup
// ====================================================

export interface FlowParameterGroup_parameterGroup {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameterGroup_parameters_parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface FlowParameterGroup_parameters_parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameterGroup_parameters_parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface FlowParameterGroup_parameters_parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: FlowParameterGroup_parameters_parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: FlowParameterGroup_parameters_parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: FlowParameterGroup_parameters_parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

export interface FlowParameterGroup_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The display type of the flow parameter
   */
  displayType: DisplayType | null;
  /**
   * The order of the flow parameter will be displayed
   */
  order: number | null;
  /**
   * The parameter of the flow parameter
   */
  parameter: FlowParameterGroup_parameters_parameter;
  /**
   * The name of the flow parameter
   */
  name: string;
  /**
   * The type of the flow parameter
   */
  type: string;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The minimum value of the flow parameter
   */
  min: number | null;
  /**
   * The maximum value of the flow parameter
   */
  max: number | null;
  /**
   * The mask of the flow parameter
   */
  mask: string | null;
  /**
   * The photo mask of the flow parameter
   */
  photoMask: string | null;
  /**
   * The orientation the photo must been taken
   */
  photoOrientation: MediaOrientation;
  /**
   * If the value is required
   */
  valueRequired: ValueRequired;
  /**
   * If the media is allowed
   */
  mediaRequirement: MediaRequirement;
  /**
   * The amount of media must be taken
   */
  mediaAmount: number | null;
  /**
   * The quality of the media
   */
  mediaQuality: MediaQuality;
  /**
   * The orientation of the media
   */
  mediaOrientation: MediaOrientation;
  /**
   * The time limit of the media in seconds
   */
  mediaTimeLimit: number | null;
  /**
   * If you must take a live photo
   */
  photoLive: boolean;
  /**
   * If you are allowed to make remarks
   */
  remarksRequirement: RemarksRequirement;
  /**
   * If you are allowed to make damages
   */
  damagesRequirement: DamagesRequirement;
  /**
   * The date of the flow parameter when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter when it was last updated
   */
  updatedAt: any | null;
}

export interface FlowParameterGroup {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The display type of the flow parameter group
   */
  displayType: DisplayType | null;
  /**
   * How the parameter group must be displayed
   */
  viewType: FlowParameterCategoryViewType;
  /**
   * The order of the flow parameter group will be displayed
   */
  order: number | null;
  /**
   * The parameter group of the flow paramteer group
   */
  parameterGroup: FlowParameterGroup_parameterGroup | null;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The flow parameters in the flow parameter group
   */
  parameters: FlowParameterGroup_parameters[];
  /**
   * The date of the flow parameter group when it was created
   */
  createdAt: any;
  /**
   * The date of the flow parameter group when it was last updated
   */
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Parameter
// ====================================================

export interface Parameter_parameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

export interface Parameter_options {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

export interface Parameter_parameterSets {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

export interface Parameter {
  __typename: "Parameter";
  /**
   * The identifier of the parameter
   */
  id: string;
  /**
   * The name of the parameter, e.g. Color
   */
  name: string;
  /**
   * The order the parameter should be displayed in
   */
  order: number;
  /**
   * The type of the parameter indicating which input field should be shown for the parameter
   */
  type: string;
  /**
   * The metric indicates the metric/unit of the parameter, e.g. km, m, cm, etc.
   */
  metric: string | null;
  /**
   * The minimum value when the parameter type is a number, a minimum value length otherwise
   */
  min: number | null;
  /**
   * The maximum value when the parameter type is a number, a maximum value length otherwise
   */
  max: number | null;
  /**
   * The input field mask indicating the 'layout' of the value e.g. ABCD 12
   */
  mask: string | null;
  /**
   * The photo mask layed over the camera view when inspecting, e.g. Serial Number Box
   */
  photoMask: string | null;
  /**
   * The parameter category this parameter belongs to
   */
  parameterCategory: Parameter_parameterCategory | null;
  /**
   * The options this parameter has available
   */
  options: Parameter_options[];
  /**
   * All the parameter sets the parameter belongs to
   */
  parameterSets: Parameter_parameterSets[];
  /**
   * The timestamp the parameter was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter was last updated
   */
  updatedAt: any | null;
  /**
   * The timestamp the parameter was removed from the system
   */
  deletedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParameterCategory
// ====================================================

export interface ParameterCategory {
  __typename: "ParameterCategory";
  /**
   * The identifier of the parameter category
   */
  id: string;
  /**
   * The name of the parameter category, e.g. Tyres, Interior, etc.
   */
  name: string;
  /**
   * The order the parameter category should be displayed in
   */
  order: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParameterOption
// ====================================================

export interface ParameterOption {
  __typename: "ParameterOption";
  /**
   * The identifier of the parameter option
   */
  id: string;
  /**
   * The order the parameter options should be displayed in
   */
  order: number | null;
  /**
   * The value of the parameter option
   */
  value: string | null;
  /**
   * Whether a remark by the inspector is required when this parameter option is selected
   */
  requiresRemark: boolean;
  /**
   * The timestamp the parameter option was created
   */
  createdAt: any;
  /**
   * The timestamp the parameter option was last updated
   */
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParameterSet
// ====================================================

export interface ParameterSet {
  __typename: "ParameterSet";
  /**
   * The identifier of the parameter set
   */
  id: string;
  /**
   * The name of the parameter set
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InspectionPlanningList
// ====================================================

export interface InspectionPlanningList_address_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface InspectionPlanningList_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: InspectionPlanningList_address_entity;
}

export interface InspectionPlanningList {
  __typename: "InspectionPlanning";
  /**
   * The inspection planning type identifier
   */
  id: string;
  /**
   * The address where the planning is about
   */
  address: InspectionPlanningList_address | null;
  /**
   * When the last inspection is
   */
  previousDate: any | null;
  /**
   * When the next inspection is
   */
  nextDate: any;
  /**
   * The frequency of the inspections
   */
  frequency: number | null;
  /**
   * If the inspections must be trusted
   */
  trusted: boolean;
  /**
   * The duration of the inspection in days
   */
  duration: number;
  /**
   * When this will be automatically planned
   */
  automaticPlannedAt: any | null;
  /**
   * The number of assets
   */
  amountOfAssets: number;
  /**
   * The percentage of assets of the next audit
   */
  percentageOfAssets: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InspectionList
// ====================================================

export interface InspectionList_asset {
  __typename: "Asset";
  /**
   * The identifier of the asset
   */
  id: string;
}

export interface InspectionList_flow {
  __typename: "Flow";
  /**
   * The id of the flow
   */
  id: string;
}

export interface InspectionList_parameterValues {
  __typename: "ParameterValue";
  /**
   * The identifier of the parameter value
   */
  id: string;
}

export interface InspectionList_address_dealer {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface InspectionList_address {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The dealer of the address
   */
  dealer: InspectionList_address_dealer | null;
}

export interface InspectionList {
  __typename: "Inspection";
  /**
   * The identifier of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The situation of the equipment when an inspection is finished
   */
  equipmentSituation: InspectionEquipmentSituation | null;
  /**
   * The timestamp the inspection is planned and becomes available to the inspector
   */
  plannedAt: any | null;
  /**
   * The timestamp the inspection should be finished and will become unavailable to
   * the inspector, the deadline of the inspection
   */
  dueAt: any | null;
  /**
   * The timestamp the inspection was submitted
   */
  submittedAt: any | null;
  /**
   * The asset the inspection should be perfomed on
   */
  asset: InspectionList_asset;
  /**
   * The flow information of this inspection
   */
  flow: InspectionList_flow | null;
  /**
   * The values gathered for the parameters attached to the inspection when the inspection was executed
   */
  parameterValues: InspectionList_parameterValues[];
  /**
   * The physical address this inspection should take place
   */
  address: InspectionList_address | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatorInfo
// ====================================================

export interface PaginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Notification
// ====================================================

export interface Notification_toUser {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The full name field of user
   */
  name: string;
}

export interface Notification {
  __typename: "Notification";
  /**
   * The identifier of the notification
   */
  id: string;
  /**
   * The name of the notification
   */
  name: string;
  /**
   * The user where the notification is send to
   */
  toUser: Notification_toUser;
  /**
   * The timestamp the notification was set
   */
  sendAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: User
// ====================================================

export interface User_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string;
}

export interface User_addresses_entity {
  __typename: "Entity";
  /**
   * The identifier of the entity
   */
  id: string;
}

export interface User_addresses {
  __typename: "Address";
  /**
   * The identifier of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address
   */
  country: string | null;
  /**
   * The latitude of the coordinate of the address
   */
  latitude: number | null;
  /**
   * The longitude of the coordinate of the address
   */
  longitude: number | null;
  /**
   * The entity of the address
   */
  entity: User_addresses_entity;
}

export interface User_roles {
  __typename: "Role";
  /**
   * The identifier of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface User_device {
  __typename: "Device";
  /**
   * The name of the device
   */
  name: string;
  /**
   * The version used on the device is outdated
   */
  outdated: boolean;
  /**
   * The version used on the device
   */
  appVersion: string | null;
  /**
   * The version used on the device is outdated
   */
  appOutdated: boolean;
  /**
   * The os on the device
   */
  systemOs: string | null;
  /**
   * The sdk version on the device
   */
  systemSdk: string | null;
  /**
   * The os used on the device is outdated
   */
  systemOsOutdated: boolean;
}

export interface User {
  __typename: "User";
  /**
   * The identifier of the user
   */
  id: string;
  /**
   * The full name field of user
   */
  name: string;
  /**
   * The full name field of user
   */
  displayName: string | null;
  /**
   * The email address of the user
   */
  email: string;
  /**
   * The first name of the user
   */
  firstName: string | null;
  /**
   * The last name of the user
   */
  lastName: string | null;
  /**
   * The phone number of the user
   */
  phone: string | null;
  /**
   * The display language of the user
   */
  language: string | null;
  /**
   * Indicator whether the user has set a password and is activated
   */
  hasPassword: boolean;
  /**
   * The entity the user belongs to
   */
  entity: User_entity;
  /**
   * The addresses that belongs to the user
   */
  addresses: User_addresses[];
  /**
   * The roles the user owns
   */
  roles: User_roles[];
  /**
   * The device used by the user
   */
  device: User_device | null;
  /**
   * The inspections that belongs to the asset
   */
  openInspectionsAmount: number | null;
  /**
   * The last inspection that belongs to the asset
   */
  inspectionsAmount: number | null;
  hasBidRights: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApprovalStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum AssetSort {
  ADDRESS_ID = "ADDRESS_ID",
  ADDRESS_NAME = "ADDRESS_NAME",
  AUCTION_CREATED_AT = "AUCTION_CREATED_AT",
  AUCTION_ENDING_AT = "AUCTION_ENDING_AT",
  AUCTION_ID = "AUCTION_ID",
  AUCTION_STARTING_AT = "AUCTION_STARTING_AT",
  AUCTION_STATUS = "AUCTION_STATUS",
  AUCTION_UPDATED_AT = "AUCTION_UPDATED_AT",
  ID = "ID",
  INSPECTION_ADDRESS_NAME = "INSPECTION_ADDRESS_NAME",
  INSPECTION_ID = "INSPECTION_ID",
  INSPECTION_STATUS = "INSPECTION_STATUS",
  NAME = "NAME",
  STATUS = "STATUS",
}

export enum AuctionStatus {
  CANCELLED = "CANCELLED",
  GRANT_DECLINED = "GRANT_DECLINED",
  NEEDS_APPROVAL = "NEEDS_APPROVAL",
  NEEDS_GRANT = "NEEDS_GRANT",
  NEEDS_INFORMATION = "NEEDS_INFORMATION",
  NO_BIDS = "NO_BIDS",
  PAID = "PAID",
  PLANNED = "PLANNED",
  RUNNING = "RUNNING",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
}

export enum AuditRating {
  CONTENT = "CONTENT",
  HALF_CONTENT = "HALF_CONTENT",
  NOT_CONTENT = "NOT_CONTENT",
}

export enum AuditStatus {
  CLOSED = "CLOSED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  POSTPONED = "POSTPONED",
}

export enum BidVisible {
  ALL = "ALL",
  NONE = "NONE",
  ONLY_HIGHEST = "ONLY_HIGHEST",
  ONLY_OWN = "ONLY_OWN",
}

export enum DamagesRequirement {
  DISALLOWED = "DISALLOWED",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export enum DisplayType {
  APP = "APP",
  REPORT = "REPORT",
}

export enum EntityFlowSort {
  CREATED_AT = "CREATED_AT",
  ENTITY = "ENTITY",
  GROUP = "GROUP",
  INSPECTION_TYPE = "INSPECTION_TYPE",
  PUBLISHED_AT = "PUBLISHED_AT",
}

export enum EntityType {
  BIDDER = "BIDDER",
  CHILD = "CHILD",
  CURRENT = "CURRENT",
  DEALER = "DEALER",
  VALUDATION_AGENCY = "VALUDATION_AGENCY",
}

export enum FlowParameterCategoryViewType {
  GROUP = "GROUP",
  IMAGES = "IMAGES",
  LIST = "LIST",
  WIZARD = "WIZARD",
}

export enum GrantStatus {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum InspectionEquipmentSituation {
  FOUND = "FOUND",
  IN_DEMONSTRATION = "IN_DEMONSTRATION",
  IN_TRANSIT = "IN_TRANSIT",
  NOT_FOUND = "NOT_FOUND",
  OTHER_SITUATION = "OTHER_SITUATION",
  RENTAL = "RENTAL",
  SERIAL_NUMBER_INCORRECT = "SERIAL_NUMBER_INCORRECT",
  SOLD = "SOLD",
  TRANSFERRED = "TRANSFERRED",
}

export enum InspectionPlanningSort {
  NEXT_DATE = "NEXT_DATE",
}

export enum InspectionStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  FINISHED = "FINISHED",
  ON_HOLD = "ON_HOLD",
  PAUSED = "PAUSED",
  PLANNED = "PLANNED",
  STARTED = "STARTED",
  SUBMITTED = "SUBMITTED",
  TRANSFER_PENDING = "TRANSFER_PENDING",
}

export enum MediaOrientation {
  BOTH = "BOTH",
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
}

export enum MediaQuality {
  QUALITY_1080P = "QUALITY_1080P",
  QUALITY_2160P = "QUALITY_2160P",
  QUALITY_480P = "QUALITY_480P",
  QUALITY_720P = "QUALITY_720P",
}

export enum MediaRequirement {
  DISALLOWED = "DISALLOWED",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export enum PostponeReason {
  AUDIT_BY_EXTERNAL_PARTY = "AUDIT_BY_EXTERNAL_PARTY",
  AUDIT_FREQUENCY_CHANGED = "AUDIT_FREQUENCY_CHANGED",
  NO_ASSETS_IN_STOCK = "NO_ASSETS_IN_STOCK",
}

export enum RemarksRequirement {
  DISALLOWED = "DISALLOWED",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

/**
 * The available directions for ordering a list of records.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserSort {
  EMAIL = "EMAIL",
  ENTITY_NAME = "ENTITY_NAME",
  ID = "ID",
  LANGUAGE_NAME = "LANGUAGE_NAME",
  NAME = "NAME",
}

export enum ValueRequired {
  ALWAYS = "ALWAYS",
  HAS_MEDIA = "HAS_MEDIA",
  NEVER = "NEVER",
}

export interface AddressesBelongsToMany {
  connect?: string[] | null;
}

/**
 * A connection or creation of an appraisal type
 */
export interface AppraisalTypeBelongsTo {
  connect: string;
}

/**
 * A connection or creation of an asset 
 */
export interface AssetBelongsTo {
  connect?: string | null;
  create?: CreateAssetInput | null;
}

/**
 * A connection of an asset status
 */
export interface AssetStatusBelongsTo {
  connect?: string | null;
}

/**
 * A connection or creation of an aution type
 */
export interface AuctionTypeBelongsTo {
  connect: string;
}

/**
 * A connection or creation of a contact
 */
export interface ContactBelongsTo {
  connect?: string | null;
  create?: CreateContactInput | null;
}

export interface CreateAppraisalInput {
  appraisalType: AppraisalTypeBelongsTo;
  value?: number | null;
  inspection: InspectionBelongsTo;
  appraiser?: UserBelongsTo | null;
  valuationAgency?: EntityBelongsTo | null;
}

/**
 * The fields used to create an asset in the system
 */
export interface CreateAssetInput {
  name: string;
  serialNumber: string;
  yearOfManufacture?: number | null;
  group?: GroupBelongsTo | null;
  model?: ModelBelongsTo | null;
}

/**
 * Definition of fields used to create an auction
 */
export interface CreateAuctionInput {
  auctionType: AuctionTypeBelongsTo;
  asset: AssetBelongsTo;
  featured?: boolean | null;
  startingAt: any;
  endingAt: any;
  parameterValues?: ParameterValueBelongsToHasMany | null;
  flow?: FlowBelongsTo | null;
}

/**
 * The fields used to create an contact in the system
 */
export interface CreateContactInput {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  mobile?: string | null;
}

/**
 * Connection Model
 */
export interface CreateEntityInput {
  name: string;
  types?: EntityType[] | null;
}

export interface CreateParameterValueInput {
  parameter: ParameterBelongsTo;
  parameterGroup?: ParameterGroupBelongsTo | null;
  value: string;
}

export interface CreateUserInput {
  entity: EntityBelongsTo;
  email: string;
  displayName?: string | null;
  firstName: string;
  lastName: string;
  phone?: string | null;
  language?: string | null;
  roles?: string[] | null;
  addresses?: AddressesBelongsToMany | null;
}

export interface DateRange {
  from: any;
  to: any;
}

/**
 * Connection Model
 */
export interface EntityBelongsTo {
  connect?: string | null;
  create?: CreateEntityInput | null;
}

/**
 * A connection or creation of an flow type
 */
export interface FlowBelongsTo {
  connect: string;
}

/**
 * Connection between a Category and a Group
 */
export interface GroupBelongsTo {
  connect?: string | null;
}

/**
 * A connection to an inspection 
 */
export interface InspectionBelongsTo {
  connect: string;
}

/**
 * Connection Model
 */
export interface ModelBelongsTo {
  connect?: string | null;
  upsert?: UpdateModelInput | null;
}

/**
 * Order by clause for the `orderBy` argument on the query `myEntityFlows`.
 */
export interface MyEntityFlowsOrderByOrderByClause {
  field: EntityFlowSort;
  order: SortOrder;
}

/**
 * Order by clause for the `orderBy` argument on the query `paginateAssets`.
 */
export interface PaginateAssetsOrderByOrderByClause {
  field: AssetSort;
  order: SortOrder;
}

/**
 * Order by clause for the `orderBy` argument on the query `paginateInspectionPlannings`.
 */
export interface PaginateInspectionPlanningsOrderByOrderByClause {
  field: InspectionPlanningSort;
  order: SortOrder;
}

/**
 * Order by clause for the `orderBy` argument on the query `paginateUsers`.
 */
export interface PaginateUsersOrderByOrderByClause {
  field: UserSort;
  order: SortOrder;
}

export interface ParameterBelongsTo {
  connect: string;
}

export interface ParameterGroupBelongsTo {
  connect: string;
}

export interface ParameterValueBelongsToHasMany {
  create?: CreateParameterValueInput[] | null;
  connect?: string[] | null;
}

export interface UpdateAppraisalInput {
  id: string;
  appraisalType?: AppraisalTypeBelongsTo | null;
  value?: number | null;
  appraiser?: UserBelongsTo | null;
  valuationAgency?: EntityBelongsTo | null;
}

/**
 * The fields used to create an asset in the system
 */
export interface UpdateAssetInput {
  id: string;
  status?: AssetStatusBelongsTo | null;
}

/**
 * Definition of fields used to update an auction
 */
export interface UpdateAuctionInput {
  id: string;
  auctionType?: AuctionTypeBelongsTo | null;
  featured?: boolean | null;
  startingAt?: any | null;
  endingAt?: any | null;
  parameterValues?: ParameterValueBelongsToHasMany | null;
  flow?: FlowBelongsTo | null;
}

/**
 * Connection between a Category and a model
 */
export interface UpdateModelInput {
  id?: string | null;
  make?: string | null;
  model?: string | null;
}

export interface UpdateUserInput {
  id: string;
  email?: string | null;
  displayName?: string | null;
  firstName: string;
  lastName: string;
  phone?: string | null;
  language?: string | null;
  roles?: string[] | null;
}

/**
 * Connection User
 */
export interface UserBelongsTo {
  connect?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
