



























































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import AuctionDetailStats from '@/components/auction/elements/AuctionDetailStats.vue';
import AuctionTypeSelect from '@/components/auction/input/AuctionTypeSelect.vue';
import {
  GrantAuctionVariables,
} from '@/types/auction';
import Input from '@/components/elements/input/Input.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';

@Component({
  components: {InputTextarea, Input, AuctionTypeSelect, Modal, AuctionDetailStats, SpinnerButton},
})
export default class GrantAuction extends Vue {
  @Prop(Object) protected auctionData!: any;

  private selectedBidIDToGrant: string | null = null;
  private remark: string | null = null;

  protected get variables(): GrantAuctionVariables | null {
    if (!this.auctionData || !this.selectedBidIDToGrant) {
      return null;
    }

    return {
      id: this.auctionData.id,
      bidId: this.selectedBidIDToGrant,
      remark: this.remark,
    };
  }

  protected onDone() {
    this.$emit('mutationResult');
  }

  protected onError(error: any) {
    this.$emit('error', error);
  }

}
