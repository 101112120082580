





import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class AuctionCountdown extends Vue {
  @Prop({type: [Date, String]}) protected date!: Date | string;

  protected milliseconds: number = 0;
  protected interval: any;

  protected get readableTime() {
    if (this.days >= 1) {
      return `${this.days} days, ${this.hoursOfDay} hours`;
    }

    if (this.hoursOfDay > 0) {
      return [
        this.addZero(this.hoursOfDay),
        this.addZero(this.minutesOfHour),
        this.addZero(this.secondsOfMinute),
      ].join(':');
    }

    if (this.minutesOfHour > 0) {
      return [this.addZero(this.minutesOfHour), this.addZero(this.secondsOfMinute)].join(':');
    }

    return this.addZero(this.secondsOfMinute);
  }

  protected get days() {
    return Math.floor(this.hours / 24);
  }

  protected get hoursOfDay() {
    return this.hours % 24;
  }

  protected get hours() {
    return Math.floor(this.minutes / 60);
  }

  protected get minutesOfHour() {
    return this.minutes % 60;
  }

  protected get minutes() {
    return Math.floor(this.seconds / 60);
  }

  protected get secondsOfMinute() {
    return this.seconds % 60;
  }

  protected get seconds() {
    return Math.floor(this.milliseconds / 1000);
  }

  protected addZero(amount: number) {
    if (amount < 10 && amount > -10) {
      return '0' + amount;
    }
    return amount;
  }

  protected created() {
    this.updateCountdown();
  }

  protected updateCountdown() {
    this.milliseconds = this.calculateTimesRemaining();

    if (this.days < 1) {
      this.startInterval();
    } else {
      this.startTimeout(this.minutesOfHour * 60 + this.secondsOfMinute);
    }
  }

  protected startInterval() {
    clearInterval(this.interval);

    this.interval = setInterval(() => {
      this.milliseconds = this.calculateTimesRemaining();
      if (this.milliseconds <= 0) {
        clearInterval(this.interval);
        this.$emit('done');
      }
    }, 1000);
  }

  protected startTimeout(seconds: number) {
    setTimeout(() => {
      this.updateCountdown();
    }, (seconds + 1) * 1000);
  }

  protected calculateTimesRemaining() {
    const now = new Date();
    const endingAt = new Date(this.date);
    const diff = endingAt.getTime() - now.getTime();

    return (diff > 0) ? diff : 0;
  }
}
