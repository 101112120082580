












import {Component, Emit, Model, Vue, Watch} from 'vue-property-decorator';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import {AppraisalType, AuctionType} from '@/types/auction';

@Component({
  components: {InputSelect},
})
export default class AuctionTypeSelect extends Vue {
  @Model('change') protected model!: AuctionType|null;

  protected value: string|null = null;

  @Watch('model', { immediate: true })
  protected onModelChange(value: AuctionType|null) {
    this.value = (value) ? value.id : null;
  }

  @Emit('change')
  protected optionChange(value: AppraisalType) {
    return value;
  }

  @Emit('textChange')
  protected textChange(value: any) {
    return value;
  }

}
