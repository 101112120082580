










import {Component, Prop, Vue} from 'vue-property-decorator';
import {AuctionList_auctionType, AuctionStatus, AuctionType} from '@/types/auction';
import {AuditStatus} from '@/types/intrador';
import {getAuditStatusTranslation} from '@/plugins/audit/AuditStatus';

@Component({})
export default class AuctionStatusLabel extends Vue {
  @Prop(String) protected status!: AuctionStatus;
  @Prop(Object) protected auctionType!: AuctionList_auctionType;

  private getAuditStatusTranslation(status: AuditStatus) {
    return getAuditStatusTranslation(status);
  }

  get label(): string {
    const defaultLabel =  this.status.replaceAll('_', ' ');

    switch (this.status) {
      case AuctionStatus.PLANNED:
        return this.$it('auction.status.planned', 'Planned') as string;
      case AuctionStatus.RUNNING:
        return this.$it('auction.status.running', 'Running') as string;
      case AuctionStatus.NO_BIDS:
        return this.$it('auction.status.no-bids', 'No bids') as string;
      case AuctionStatus.NEEDS_GRANT:
        return this.$it('auction.status.needs-grant', 'Needs grant') as string;
      case AuctionStatus.NEEDS_APPROVAL:
        return this.$it('auction.status.needs-approval', 'Needs approval') as string;
      case AuctionStatus.GRANT_DECLINED:
        return this.$it('auction.status.grant-declined', 'Grant declined') as string;
      case AuctionStatus.WAITING_FOR_PAYMENT:
        return this.$it('auction.status.waiting-for-payment', 'Waiting for payment') as string;
      case AuctionStatus.PAID:
        return this.$it('auction.status.paid', 'Paid') as string;
      case AuctionStatus.CANCELLED:
        return this.$it('auction.status.cancelled', 'Cancelled') as string;
    }


    return defaultLabel;
  }
}
