



















































import {Component, Mixins} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import InputUser, {UserSelectResponse} from '@/components/account/input/InputUser.vue';
import AppraisalTypeSelect from '@/components/appraisal/input/AppraisalTypeSelect.vue';
import {
  AppraisalType,
  CreateAppraisalInput, CreateAppraisalVariables, EntityType,
  PaginateAssets_paginateAssets_data, PaginateEntities_paginateEntities_data,
  PaginateUsers_paginateUsers_data,
} from '@/types/auction';
import {Inspections_paginateInspections_data} from '@/types/intrador';
import FileUpload from '@/components/elements/input/FileUpload.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputEntity, {EntitySelectResponse} from '@/components/entity/input/InputEntity.vue';

type BulkCreateAppraisalItem = PaginateAssets_paginateAssets_data | Inspections_paginateInspections_data;

@Component({
  components: {InputEntity, InputText, FileUpload, AppraisalTypeSelect, InputUser, ActionModal},
})
export default class BulkCreateAppraisals extends Mixins<ActionMixin<BulkCreateAppraisalItem>>(ActionMixin) {
  public title: string = this.$it('inspection.action.appraisal.title', 'Create appraisal') as string;
  public icon?: string = 'dollar-sign';
  public permission: string = 'appraisals-create';

  public appraisalType: AppraisalType|null = null;
  public valuationAgency: EntitySelectResponse|null = null;
  public appraiser: UserSelectResponse|null = null;

  protected value: number|null = null;
  protected files: FileList|null = null;

  protected EntityType = EntityType;

  get mutation() {
    return (this.single)
      ? require('@/graphql/mutations/appraisals/CreateAppraisal.gql')
      : require('@/graphql/mutations/appraisals/BulkCreateAppraisals.gql');
  }

  get variables(): any {
    if (this.appraisalsVariables === null) {
      return null;
    }

    if (this.single) {
      const appraisal = this.appraisalsVariables[0];
      const data: CreateAppraisalVariables = {appraisal};

      if (this.files && this.files.length > 0) {
        data.upload = this.files[0];
      }

      return data;
    }

    return {appraisals: this.appraisalsVariables};
  }

  get appraisalsVariables(): CreateAppraisalInput[] | null {
    if (!this.appraisalType) {
      return null;
    }

    if (this.appraisalType.valueRequired && this.value === null) {
      return null;
    }

    if (!this.appraisalType.valueRequired && this.valuationAgency === null && this.appraiser === null) {
      return null;
    }

    return this.availableItems.map((inspection: any): CreateAppraisalInput => {
      const data: any = {
        appraisalType: {
          // Add ! because it is already tested outside the map function
          connect: this.appraisalType!.id,
        },
        inspection: {
          connect: inspection.id,
        },
      };

      if (this.valuationAgency && this.appraiser) {
        data.valuationAgency = (this.valuationAgency.id !== null)
          ? {connect: this.valuationAgency.id}
          : {create: {name: this.valuationAgency.name, types: [EntityType.VALUDATION_AGENCY]}};

        data.appraiser = {
          connect:  (this.appraiser.id !== null)
            ? this.appraiser.id
            : this.appraiser.email,
        };
      }

      if (this.value) {
        data.value = this.value;
      }

      return data;
    });
  }

  get availableItems(): any[] {
    return this.selected
      .map((item) => {
        return ('lastInspection' in item) ? item.lastInspection : item;
      })
      .filter((inspection: any) => inspection && inspection.submittedAt !== null);
  }

  protected open() {
    this.onSelect();
  }

  protected onSelect() {
    this.appraisalType = null;
    this.appraiser = null;
    this.value = null;
    this.files = null;

    (this.$refs.actionModal as ActionModal).open();
  }
}
