





































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {PaginateAssets_paginateAssets_data} from '@/types/auction';
import AuctionCountdown from '@/components/auction/AuctionCountdown.vue';
import CreateAuctionAction from '@/components/auction/actions/CreateAuctionAction.vue';
import ApproveAuctionGrantAction from '@/components/auction/actions/ApproveAuctionGrantAction.vue';
import DeclineAuctionGrantAction from '@/components/auction/actions/DeclineAuctionGrantAction.vue';
import GrantAuctionAction from '@/components/auction/actions/GrantAuctionAction.vue';
import RequestAuctionGrantApprovalAction from '@/components/auction/actions/RequestAuctionGrantApprovalAction.vue';
import AuctionMixin from '@/components/auction/AuctionMixin';
import MarkAuctionAsPaidAction from '@/components/auction/actions/MarkAuctionAsPaidAction.vue';
import ValueColumn from '@/layouts/back-office/elements/columns/ValueColumn.vue';
import AuctionValueColumn from '@/layouts/back-office/elements/columns/AuctionValueColumn.vue';
import AuctionActionColumn from '@/components/auction/columns/AuctionActionColumn.vue';
import AuctionDetailModal from '@/components/auction/modals/AuctionDetailModal.vue';
import QueryAuctionDetailModal from '@/components/auction/modals/QueryAuctionDetailModal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import AuctionStatusLabel from '@/components/auction/elements/AuctionStatusLabel.vue';

@Component({
  components: {
    SpinnerButton,
    AuctionDetailModal,
    AuctionActionColumn,
    ValueColumn,
    AuctionValueColumn,
    MarkAuctionAsPaidAction,
    RequestAuctionGrantApprovalAction,
    GrantAuctionAction,
    AuctionStatusLabel,
    DeclineAuctionGrantAction, ApproveAuctionGrantAction, CreateAuctionAction, AuctionCountdown,
  },
})
export default class AuctionColumn extends Mixins(AuctionMixin) {
  @Prop(Object) protected asset!: PaginateAssets_paginateAssets_data;

  protected get flowId() {
    if (!this.asset.lastInspection || !this.asset.lastInspection.flow) {
      return null;
    }

    return this.asset.lastInspection.flow.id;
  }

  protected get parameterValuesIds() {
    if (!this.asset.lastInspection) {
      return [];
    }

    return this.asset.lastInspection.parameterValues.map((pv) => pv.id);
  }

  protected openDetail(spinning: any) {
    spinning(true);

    this.$emit('onOpen', this.asset.lastAuction!.id, () => spinning(false));
  }

  private mounted() {
    if (this.auction && this.$router.currentRoute.params && this.$router.currentRoute.params.id) {
      const auctionID = this.$router.currentRoute.params.id;
      if (this.auction.id === auctionID) {
        this.$nextTick(() => {
          const auctionDetail: QueryAuctionDetailModal = this.$refs.auctionDetail as QueryAuctionDetailModal;
          auctionDetail.open(() => null);
        });
      }
    }
  }

}
